import axios from 'axios';
const url = require('url');

export const LOGOUT_USER = 'LOGOUT_USER';
export const logout = () => ({ type: LOGOUT_USER });

export default {
    setupInterceptors: (store) => {
        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {

            if( error.config && error.config.url === '/login'){
                return Promise.reject(error);
            }
            //catches if the session ended!
            if (error.response && error.response.status === 401) {
                store.dispatch(logout());
                //localStorage.clear();
            }
            // todo: reformat error ??
            return Promise.reject(error);
        });


        axios.interceptors.request.use(function (request) {
            // parse window location to get current page and store it in user ctx on backend
            const location = window.location.hash.slice(1);
            const uri = url.parse(location);
            const params = new URLSearchParams(uri.search);
            if (params.get("ws")) {
                request.headers.AgoraWS = params.get("ws");
                request.headers.AgoraPathname = uri.pathname;
            }

            // add Authorization header
            if ((request.url.startsWith('/api/1.0') || request.url == '/logout') && !request.headers?.Authorization) {
                const sessionId = localStorage.getItem('x-token');
                request.headers.Authorization = 'Bearer ' + sessionId;
            }
            return request;
        });
    }
};
