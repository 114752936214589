/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { Button, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import classnames from 'classnames/dedupe';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';
import { addToast, changepassword } from '../../actions';
import { makeCancelable } from '../../store/utils';

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, oldPassword: '', newPassword: '', confirmPassword: '', actionError:'' };
    }
    componentWillUnmount() {
        this.update && this.update.cancel();
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true, actionError:'' });
        this.update = makeCancelable(this.props.changepassword(this.state.newPassword, this.state.oldPassword));
        this.update.promise
            .then(() => {
                this.setState({ loading: false });
                this.props.addToast({
                    title: 'Agora Software',
                    content: this.props.t('Password changed!'),
                    time: new Date(),
                    duration: 4000,
                });
                this.props.onSubmit();
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.setState({ loading: false,
                    actionError: this.props.t("Unable to change password.")
                });
            })
            .finally(() => {
                this.update = null;
            });
    };

    onClose = () => {
        if (this.state.loading) return;
        this.props.onClose();
    };

    setOldPassword = ({ target: { value } }) => {
        this.setState({ oldPassword: value });
    };
    setNewPassword = ({ target: { value } }) => {
        this.setState({ newPassword: value }, this.state.newPasswordError ? this.checkNewPassword : () => {});
    };
    setConfirmPassword = ({ target: { value } }) => {
        this.setState({ confirmPassword: value }, this.state.confirmPasswordError ? this.checkConfirmPassword : () => {});
    };

    checkNewPassword = ()=>{
        const { newPassword, confirmPassword } = this.state;
        const isValid = newPassword && newPassword.length >= 6;
        const isValid2 = !confirmPassword.length || confirmPassword === newPassword;

        this.setState({
            newPasswordError: isValid ? '' : this.props.t('Password must be at least 6 characters long'),
            confirmPasswordError: isValid2 ? '' : this.props.t('Passwords need to match'),
        });
        return isValid;
    }
    checkConfirmPassword = ()=>{
        const { newPassword, confirmPassword } = this.state;
        const isValid = confirmPassword === newPassword;
        this.setState({
            confirmPasswordError: isValid ? '' : this.props.t('Passwords need to match'),
        });
        return isValid;
    }

    render() {
        const { oldPassword, newPassword, newPasswordError, confirmPassword, confirmPasswordError, actionError, loading } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                <Modal isOpen={true} toggle={this.onClose} fade className={`rui-snippet rui-snippet-frame ${this.props.className}`}>
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title h2">Change password &apos;{this.props.auth.login}&apos;</h5>
                            <Button className="close" color="" onClick={this.onClose}>
                                <Icon name="x" />
                            </Button>
                        </div>

                        <ModalBody className="rui-snippet-preview pb-5 mb-15">
                            <FormGroup>
                                <Label for="imageName">{t("Old password")}</Label>
                                <Input type="password" name="oldPassword" id="oldPassword" value={oldPassword} onChange={this.setOldPassword} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="imageName">{t("New password")}</Label>
                                <Input
                                    className={classnames('form-control', { 'is-invalid': newPasswordError })}
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    value={newPassword}
                                    onBlur={this.checkNewPassword}
                                    onChange={this.setNewPassword}
                                />
                                 {newPasswordError ? <div className="invalid-feedback">{newPasswordError}</div> : ''}
                            </FormGroup>
                            <FormGroup>
                                <Label for="imageName">{t("Confirm password")}</Label>
                                <Input
                                    className={classnames('form-control', { 'is-invalid': confirmPasswordError })}
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onBlur={this.checkConfirmPassword}
                                    onChange={this.setConfirmPassword} />
                                {confirmPasswordError ? <div className="invalid-feedback">{confirmPasswordError}</div> : ''}
                            </FormGroup>
                            {actionError ? <div style={{display: 'block'}} className="invalid-feedback">{actionError}</div> : ''}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" disabled={loading} onClick={this.onClose}>
                                {t("Close")}
                            </Button>{' '}
                            <Button
                                type="submit"
                                color="brand"
                                disabled={loading || !newPassword.length || confirmPassword !== newPassword || !oldPassword.length || !!newPasswordError.length || !!confirmPasswordError.length}
                                onClick={this.onSubmit}
                            >
                                {t("Submit")}
                                {loading ? <Spinner /> : ''}
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </Fragment>
        );
    }
}
Content.defaultProps = {
    className: '',
};

Content.propTypes = {
    className: PropTypes.string,
    settings: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,

    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    addToast: PropTypes.func.isRequired,
    changepassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth }) => ({
        settings,
        auth,
    }),
    { addToast, changepassword },
)(withTranslation("common")(Content));
