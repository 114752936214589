/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Collapse, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';

import { makeCancelable } from '../../store/utils';
import { get_vthings } from '../../actions';

import UserAccess from './vthings/users_access';
import AccessCode from './vthings/access_code';

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vthings: [],
            loading: true,
        };
    }
    componentDidMount = () => {
        this.getVthings();
        window.jQuery(window).trigger('rui-ajax-loaded');
    };

    componentWillUnmount = () => {
        this.loadvthings && this.loadvthings.cancel();
        window.jQuery(window).trigger('rui-ajax-unload');
    };
    componentDidUpdate(prevPros) {
        if (this.props.app.query.ws !== prevPros.app.query.ws) {
            this.loadvthings && this.loadvthings.cancel();
            this.setState({ loading: true, vthings: [] });
            this.getVthings();
        }
    }
    getVthings = () => {
        if (!this.props.app || !this.props.app.query) return;
        this.loadvthings = makeCancelable(this.props.get_vthings(this.props.app.query.ws));
        this.loadvthings.promise
            .then((result) => {
                const vthings = result.data;
                const vt = vthings
                    .filter((item) => item.auth == true)
                    .map((item) => ({ value: item.vthingId, label: item.name.replace(/^[^-]*-/, '').replace(/^vthing_/, '') }))
                    .sort((a, b) => a.label.localeCompare(b.label));
                this.setState({ vthings: vt, vthing: vt.length > 0 ? vt[0] : null, loading: false });
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
            });
    };

    handleVthing = (vthing) => {
        if (this.state.vthing.value !== vthing.value) {
            this.setState({ vthing });
        }
    };

    render() {
        const { loading, vthings, vthing } = this.state;
        if (loading) return <Spinner color="secondary" type="grow"></Spinner>;
        if (!vthings.length) return <Alert color="brand">No services available</Alert>;
        return (
            <Fragment>
                <div className="row ">
                    <div className="col-12 col-lg-2">
                        <div className="card">
                            <div className="card-body pt-20 pr-10 pb-20 pl-10">
                                <div className="menu-vthing-list">
                                    <ul className="nav menu-vthing-name rui-scrollbar flex-column mnt-3">
                                        {vthings.map((item) => {
                                            return (
                                                <div key={item.value} className="accordion-group">
                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                    <a
                                                        href="#"
                                                        className="collapse-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleVthing(item);
                                                        }}
                                                    >
                                                        {item.label}
                                                    </a>
                                                    <Collapse isOpen={item.value === vthing.value}>
                                                        <div className="collapse-content px-5">
                                                            <li>
                                                                <Link
                                                                    className={`nav-link px-5 ${
                                                                        this.props.location.pathname === '/users/access_users' ? 'active' : ''
                                                                    }`}
                                                                    onClick={() => this.handleVthing(item)}
                                                                    to={`/users/access_users?ws=${this.props.app.query.ws}`}
                                                                >
                                                                    <Icon name="user-check" />
                                                                    <span>Guests</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className={`nav-link px-5 ${
                                                                        this.props.location.pathname === '/users/access_codes' ? 'active' : ''
                                                                    }`}
                                                                    onClick={() => this.handleVthing(item)}
                                                                    to={`/users/access_codes?ws=${this.props.app.query.ws}`}
                                                                >
                                                                    <Icon name="shield" />
                                                                    <span>PIN codes</span>
                                                                </Link>
                                                            </li>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10">
                        {this.props.location.pathname === '/users/access_users' && <UserAccess vthing={this.state.vthing}></UserAccess>}
                        {this.props.location.pathname === '/users/access_codes' && <AccessCode vthing={this.state.vthing}></AccessCode>}
                    </div>
                </div>
            </Fragment>
        );
    }
}

Content.propTypes = {
    settings: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,

    get_vthings: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth, app }) => ({
        settings,
        auth,
        app,
    }),
    {
        get_vthings,
    },
)(withRouter(Content));
