/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
window.jQuery.DataTable = require( 'datatables' );

/**
 * Component
 */
class DataTables extends Component {
    constructor( props ) {
        super( props );

        this.ref = createRef();
    }

    componentDidMount() {
        if ( ! this.ref ) {
            return;
        }

        const {
            data,
        } = this.props;

        window.jQuery( this.ref.current ).DataTable( data );
        window.jQuery(window).on("resize", this.handleResize);
    }

    handleResize = ()=> {
        window.jQuery( this.ref.current ).dataTable().fnDraw();
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillUnmount() {
        window.jQuery(window).off("resize", this.handleResize);
        if ( ! this.ref ) {
            return;
        }

        window.jQuery( this.ref.current )
            .DataTable()
            .destroy( true );
    }

    render() {
        const {
            className,
            children,
        } = this.props;

        return (
            <table className={ className } ref={ this.ref }>
                { children }
            </table>
        );
    }
}

DataTables.defaultProps = {
    className: '',
};

DataTables.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    data: PropTypes.any.isRequired,
};

export default DataTables;
