/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import Tabs from '../tabs';

const TabNavItem = (props) => {
    const [tips, setTips] = useState(false);
    const callbackRef = (node) => {
        if (node) {
            setTips(node.offsetWidth < node.scrollWidth);
        }
    };
    return (
        <>
            <Tabs.NavItem {...props}>
                <div ref={callbackRef} id={`tab_${props.id}`}>
                    {props.children}
                </div>
                {props.menu && props.menu()}
            </Tabs.NavItem>
            {tips && (
                <UncontrolledPopover placement="bottom" target={`tab_${props.id}`} popperClassName="agora-tips" trigger="hover">
                    <PopoverBody className="py-5">{props.tips}</PopoverBody>
                </UncontrolledPopover>
            )}
        </>
    );
};

TabNavItem.propTypes = {
    menu: PropTypes.func,
    id: PropTypes.string.isRequired,
    tips: PropTypes.string.isRequired,
    children: PropTypes.any,
};

export default TabNavItem;
