import axios, { CanceledError } from 'axios';
import { getDefaultModel } from '../pages/Tasks/components/ItemTypes';
import { makeCancelable } from '../store/utils';
// ######################################################################
//                              Tasks
// ######################################################################

export const get_events = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/events`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_functions = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/functions`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const create_custom_event = (workspaceName = 'default', event_name, attribute_name, attribute_type, attribute_value = null) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .post(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/event`,
                    {
                        event_name,
                        attribute_name,
                        attribute_type,
                        attribute_value,
                    },
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_custom_event = (workspaceName = 'default', event_id, attribute_name = null) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
            data: {
                attribute_name,
            },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/event/${encodeURIComponent(event_id)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_stack = (workspaceName = 'default', event_id = null) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                event_id,
            },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/events/stack`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_scripts = (workspaceName = 'default') => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/scripts`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const get_script = (workspaceName = 'default', script_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script/${encodeURIComponent(script_id)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

/**
 *
 * @param {string} workspaceName
 * @param {*} opts
 * @param {string} opts.name name of the new script
 * @param {string} opts.type type of the new script (not used when duplicate)
 * @param {*} script if script is set create a script copy
 *          else create a new script with template based on opts.type
 * @returns Promise
 */
export const create_script = (workspaceName = 'default', opts, script) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .post(
                    `/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script`,
                    {
                        script_name: opts.name,
                        version: 1,
                        production: false,
                        type: script ? script.type : opts.type,
                        tags: opts.tags,
                        groupName: opts.groupName,
                        className: script ? script.className : opts.className,
                        scriptCode: script ? script.scriptCode : '//',
                        attributes: script
                            ? script.attributes
                            : {
                                  triggers: [],
                                  consoleModel: getDefaultModel(opts.type, opts),
                              },
                    },
                    config,
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const update_script = (workspaceName = 'default', script) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script/${encodeURIComponent(script.script_id)}`, script, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};
export const play_script = (workspaceName = 'default', script, body = null) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script/${encodeURIComponent(script.script_id)}/play`, body, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const revert_script = (workspaceName = 'default', script_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script/${encodeURIComponent(script_id)}/revert`, null, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_script = (workspaceName = 'default', script_id) => {
    return (dispatch, getState) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script/${encodeURIComponent(script_id)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              output script
// ######################################################################

export const SCRIPT_OUTPUT_ADD = 'SCRIPT_OUTPUT_ADD';
export const script_output = (payload) => ({
    type: SCRIPT_OUTPUT_ADD,
    payload,
});

export const SCRIPT_OUTPUT_CLEAR = 'SCRIPT_OUTPUT_CLEAR';
export const script_output_clear = (payload) => ({
    type: SCRIPT_OUTPUT_CLEAR,
    payload,
});

const subscription = {
    script_id: '',
    timeout_id: 0,
    _cancelable: null,
    get cancelable() {
        return this._cancelable;
    },
};

export const subscribe_script =
    (workspaceName = 'default', script) =>
    (dispatch, getState) => {
        if (subscription.timeout_id) {
            clearTimeout(subscription.timeout_id);
            subscription.timeout_id = 0;
        }
        if (subscription.script_id == script.script_id)
            return () => {
                subscription._cancelable.stop();
            };
        subscription._cancelable?.stop();
        subscription.script_id = script.script_id;
        let offset = 0;
        const source = axios.CancelToken.source();
        const config = {
            params: {},
            cancelToken: source.token,
            headers: { Accept: 'text/event-stream' },
            timeout: 0,
            responseType: 'stream',
            onDownloadProgress: ({ currentTarget }) => {
                const message = currentTarget.response.slice(offset);
                offset = currentTarget.response.length;
                dispatch(script_output({ script_id: script.script_id, message }));
            },
        };
        const cancelable = makeCancelable(
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/script/${encodeURIComponent(script.script_id)}/output`, config)
                .then(() => {
                    subscription.script_id = '';
                    subscribe_script(workspaceName, script)(dispatch, getState);
                })
                .catch((err) => {
                    subscription.script_id = '';
                    if (err.response && err.response.status === 401) {
                        dispatch(script_output_clear());
                        return;
                    }
                    if (!err.isCanceled && !(err instanceof CanceledError)) {
                        subscription.timeout_id = setTimeout(() => {
                            subscription.timeout_id = 0;
                            subscribe_script(workspaceName, script)(dispatch, getState);
                        }, 3000);
                    } else {
                        dispatch(script_output_clear());
                    }
                }),
            source,
        );
        subscription._cancelable = cancelable;
        cancelable.promise.catch((err) => {
            /* remove error from console: nothing to do */
        });
        return () => {
            subscription._cancelable.stop();
        };
    };
