import { v4 as uuid } from 'uuid';

export const ItemTypes = {
    CONDITION: 'condition',
    CONDITION_NEW: 'condition_new',
    TRIGGER_NEW: 'trigger_new',
    ACTION_NEW: 'action_new',
    TRIGGER: 'trigger',
    ACTION: 'action',
};

export const TreeTypes = {
    NODE: 0,
    OBJECT: 1,
};

export const ConditionTypes = {
    AND: 0,
    OR: 1,
};

export const DEFAULT_CONDITION = {
    type: TreeTypes.OBJECT,
    name: 'new condition',
    trigger_id: 'none',
    enabled: true,
    attribute_name: '',
    attribute_unit: 3, // string
    condition: '<=',
    condition_value: '0',
};

export const DEFAULT_COMMAND = {
    type: TreeTypes.OBJECT,
    name: 'new command',
    target: '',
    action: 'verbatim',
    command: '',
    event_id: 'none',
    enabled: true,
    attribute_name: '',
    attribute_unit: 3, // string
};

export const ScriptBlocTypes = {
    TRIGGERS: 'triggers',
    COMMANDS: 'commands',
};

export const ScriptTypes = {
    SCRIPT_STD: 'std',
    SCRIPT_ALARM: 'alarm',
    SCRIPT_DIALOG: 'dialog',
    SCRIPT_ADVANCED: 'adv',
};

export const ScriptTypesIcon = {
    [ScriptTypes.SCRIPT_STD]: 'file',
    [ScriptTypes.SCRIPT_ALARM]: 'toggle-left',
    [ScriptTypes.SCRIPT_DIALOG]: 'message-square',
    [ScriptTypes.SCRIPT_ADVANCED]: 'code',
};

export const SelectScriptTypes = [
    { label: 'Standard', value: ScriptTypes.SCRIPT_STD },
    { label: 'Status', value: ScriptTypes.SCRIPT_ALARM },
    { label: 'Dialog', value: ScriptTypes.SCRIPT_DIALOG },
    { label: 'Advanced', value: ScriptTypes.SCRIPT_ADVANCED },
];

export const ScriptClassName = {
    CLASS_VALIDATOR: 'validator',
};

export const ScriptTriggerClassName = {
    [ScriptClassName.CLASS_VALIDATOR]: [
        { value: '$validator', label: 'Response' },
        { value: '%target', label: 'User' },
        { value: '%route', label: 'Source' },
    ],
};

export const getSelectedScriptTriggerClassName = (filter) => {
    const list = ScriptTriggerClassName[filter.name];
    if (list) return list.find((item) => filter.id === item.value);
    return null;
};
export const getClassAttributes = (type, id) => {
    switch (type) {
        case ScriptClassName.CLASS_VALIDATOR:
            if (id == '$validator')
                return {
                    attributeName: id.replace(/^\$/, ''),
                    attributeUnit: {
                        name: 'Boolean',
                        type: 0, // boolean
                    },
                    attributeType: 'Boolean',
                    attributeFilterLabel: 'n/a',
                };
            if (id == '%target')
                return {
                    attributeName: id.replace(/^\%/, ''),
                    attributeUnit: {
                        name: 'String',
                        type: 3, // string
                    },
                    attributeType: 'String',
                    attributeFilterLabel: 'n/a',
                };
            if (id == '%route')
                return {
                    attributeName: id.replace(/^\%/, ''),
                    attributeUnit: {
                        name: 'String',
                        type: 3, // string
                    },
                    attributeType: 'String',
                    attributeFilterLabel: 'n/a',
                };
        default:
            return null;
    }
};

export function getContext(label) {
    return {
        label,
        states: { id: uuid(), type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
        commands: { id: uuid(), type: TreeTypes.NODE, children: [] },
    };
}

export function getDefaultModel(type = ScriptTypes.SCRIPT_STD, options = {}) {
    switch (type) {
        case ScriptTypes.SCRIPT_DIALOG:
            //  [{ name: 'age', type: 'number', label: '...' }],
            return {
                advanced: false,
                intent: options.intent, // 'GET_AGE',
                rules: [
                    /*
                    {
                        states: { id: uuid(), type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
                        commands: {
                            id: uuid(),
                            type: TreeTypes.NODE,
                            children: [
                                { id: '00000000-0000-4000-0000-00000000then', type: TreeTypes.NODE, children: [] },
                                { id: '00000000-0000-4000-0000-00000000else', type: TreeTypes.NODE, children: [] },
                            ],
                        },
                    },
                    */
                    {
                        label: 'default',
                        states: { id: '00000000-0000-4000-0000-000000000000', type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
                        commands: { id: '00000000-0000-4000-0000-000000000000', type: TreeTypes.NODE, children: [] },
                    },
                ],
                error: {
                    states: { id: '00000000-0000-4000-0000-0000000ERROR', type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
                    commands: { id: '00000000-0000-4000-0000-0000000ERROR', type: TreeTypes.NODE, children: [] },
                },
            };
        case ScriptTypes.SCRIPT_ALARM:
            return {
                advanced: false,
                rules: [
                    {
                        label: 'off',
                        states: { id: '00000000-0000-4000-0000-000000000Tof', type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
                        commands: { id: '00000000-0000-4000-0000-000000000Cof', type: TreeTypes.NODE, children: [] },
                    },
                    {
                        label: 'on',
                        states: { id: '00000000-0000-4000-0000-000000000Ton', type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
                        commands: { id: '00000000-0000-4000-0000-000000000Con', type: TreeTypes.NODE, children: [] },
                    },
                ],
                groupName: options.groupName,
            };
        case ScriptTypes.SCRIPT_STD:
        default:
            return {
                states: { id: '00000000-0000-4000-0000-000000000000', type: TreeTypes.NODE, condition: ConditionTypes.AND, children: [] },
                commands: {
                    id: '00000000-0000-4000-0000-000000000000',
                    type: TreeTypes.NODE,
                    children: [
                        { id: '00000000-0000-4000-0000-00000000then', type: TreeTypes.NODE, children: [] },
                        { id: '00000000-0000-4000-0000-00000000else', type: TreeTypes.NODE, children: [] },
                    ],
                },
                groupName: options.groupName,
            };
    }
}
