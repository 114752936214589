import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Plublish = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <rect x="2" y="2" width="20" height="6" rx="2" ry="2"></rect>
            <line x1="6" y1="5" x2="6.01" y2="5"></line>

            <polyline points="16 15 12 11 8 15"></polyline>
            <line x1="12" y1="12" x2="12" y2="22"></line>
        </svg>
    );
});

Plublish.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Plublish.displayName = 'AlertTriangle';

export default Plublish;
