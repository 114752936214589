import { SCRIPT_OUTPUT_ADD, SCRIPT_OUTPUT_CLEAR } from '../actions/scripts';
// initial state.
const INITIAL_STATE = {
    output: { script_id: '', logs: [] },
};

const scripts = (state = INITIAL_STATE.output, action) => {
    switch (action.type) {
        case SCRIPT_OUTPUT_CLEAR: {
            const newState = { ...state, logs: [] };
            return newState;
        }
        case SCRIPT_OUTPUT_ADD: {
            if (action.payload.script_id == state.script_id) {
                const logs = state.logs.slice(-50);
                logs.push({ time: Date.now(), message: action.payload.message });
                const newState = { ...state, logs };
                return newState;
            } else {
                const newState = { ...state, script_id: action.payload.script_id, logs: [] };
                newState.logs.push({ time: Date.now(), message: action.payload.message });
                return newState;
            }
        }
        default:
            return state;
    }
};

export default scripts;
