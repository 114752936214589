/* eslint-disable jsx-a11y/scope */
/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

/**
 * Internal Dependencies
 */
import Icon from '../../../components/icon';
import DataTables from '../../../components/data-tables';

import { makeCancelable } from '../../../store/utils';
import { get_pin_codes, delete_pin_code } from '../../../actions';
import ModalConfirmation from '../../../components/modal/modalConfirmation';
import ModalCode from './modalCode';
import { convertDate } from '../../../tools';

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            codes: [],
            filter: '',
            delConfirm: false,
            delMessage: '',
            modalOpen: false,
            error: false,
        };
    }
    componentDidMount = () => {
        this.getCodes();
    };

    componentWillUnmount = () => {
        this.getcodes && this.getcodes.cancel();
        this.deletecode && this.deletecode.cancel();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.vthing.value !== prevProps.vthing.value) {
            this.getcodes && this.getcodes.cancel();
            this.setState({ loading: true, codes: [] });
            this.getCodes();
        }
    };

    getCodes = () => {
        if (!this.props.app || !this.props.app.query) return;
        this.getcodes = makeCancelable(this.props.get_pin_codes(this.props.app.query.ws, this.props.vthing.value));
        this.getcodes.promise
            .then((result) => {
                this.getcodes = null;
                this.setState({ loading: false, codes: result.data || [] });
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.getcodes = null;
                this.setState({ loading: false });
            });
    };

    editCode = (code) => (e) => {
        e.preventDefault();
        this.setState({ modalOpen: true, selected: code });
    };

    addCode = (e) => {
        e.preventDefault();
        this.setState({ modalOpen: true, selected: {} });
    };

    codeSubmitted = () => {
        this.setState({ modalOpen: false, loading: true, selected: {} });
        this.getCodes();
    };
    closeModal = () => {
        this.setState({ modalOpen: false, selected: {} });
    };

    handleDelConfirm = (code) => (e) => {
        e.preventDefault();
        this.setState({ delConfirm: true, delId: code._id, delMessage: `Are you sure to delete?` });
    };

    removeCode = (validate) => {
        const { delId } = this.state;
        this.setState({ delConfirm: false, loading: true, delId: '', delMessage: '' });
        if (!validate) return;

        this.deletecode = makeCancelable(this.props.delete_pin_code(this.props.app.query.ws, this.props.vthing.value, delId));
        this.deletecode.promise
            .then(() => {
                this.getCodes();
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.setState({ loading: false });
            })
            .finally(() => {
                this.deletecode = null;
            });
    };

    handleSearch = (e) => {
        const { value } = e.target;
        this.setState({ filter: value, loading: true }, () => this.setState({ loading: false }));
    };

    /*=============================================
    =            Section render                   =
    =============================================*/
    render_codes = () => {
        const { codes, filter } = this.state;
        let tmp = codes;
        if (filter.length > 0) tmp = codes.filter((code) => `${code.value}`.indexOf(filter) != -1);
        return tmp
            .map((code, idx) => {
                return (
                    <tr key={code._id}>
                        <td scope="row" className="rui-filemanager-table-login">
                            <span className="rui-filemanager-file">{code.value}</span>
                        </td>
                        <td scope="row" className="rui-filemanager-table-login">
                            <span className="rui-filemanager-file">{code.start ? convertDate(code.start) : '-'}</span>
                        </td>
                        <td scope="row" className="rui-filemanager-table-login">
                            <span className="rui-filemanager-file">{code.end ? convertDate(code.end) : '-'}</span>
                        </td>
                        <td scope="row" className="rui-filemanager-table-login">
                            <span className="rui-filemanager-file">{code.count >= 0 ? code.count : '-'}</span>
                        </td>
                        <td className="rui-filemanager-table-actions">
                            <span className="rui-filemanager-file">
                                <Link className="rui-filemanager-file-icon mr-10" to="#" onClick={this.editCode(code)}>
                                    <Icon name="edit" />
                                </Link>
                                <Link className="rui-filemanager-file-icon mr-10" to="#" onClick={this.handleDelConfirm(code)}>
                                    <Icon name="x" />
                                </Link>
                            </span>
                        </td>
                    </tr>
                );
            });
    };

    render() {
        const { loading, delConfirm, delMessage, modalOpen, selected, filter } = this.state;
        if (loading) return <Spinner color="secondary" type="grow"></Spinner>;

        return (
            <Fragment>
                {delConfirm && <ModalConfirmation isOpen={delConfirm} title="Confirm delete" content={delMessage} onResult={this.removeCode} />}
                {modalOpen && (
                    <ModalCode
                        code={selected}
                        workspace={this.props.app.query.ws}
                        vthing={this.props.vthing}
                        onClose={this.closeModal}
                        onSubmit={this.codeSubmitted}
                    ></ModalCode>
                )}
                <div>
                    <div>
                        <div className="rui-filemanager">
                            <div className="rui-filemanager-head">
                                <div className="row sm-gap vertical-gap align-items-center">
                                    <div className="col">
                                        <div className="input-group">
                                            <div className="input-group-prepend mnl-3">
                                                <button type="button" className="btn btn-clean btn-grey-5 mb-0 mnl-10">
                                                    <Icon name="search" />
                                                </button>
                                            </div>
                                            <input
                                                type="search"
                                                value={filter}
                                                className="form-control form-control-clean rui-search-input"
                                                placeholder="Type to search..."
                                                onChange={this.handleSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-brand btn-sm btn-uniform btn-round" onClick={this.addCode}>
                                            <Icon name="plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {loading && <Spinner color="secondary" type="grow"></Spinner>}
                            {!loading && (
                                <Fragment>
                                    <div className="rui-filemanager-content">
                                        <div className="table-responsive-lg">
                                            <DataTables
                                                id="toto"
                                                className="rui-datatable rui-filemanager-table table mb-0"
                                                data={{
                                                    initComplete: () => {
                                                        window.jQuery('.dataTables_scrollBody:not(.rui-scrollbar)').addClass('rui-scrollbar');
                                                        window.jQuery(window).trigger('rui-ajax-loaded');
                                                    },
                                                    scrollY: 'calc(100vh - 475px)',
                                                    order: [0, 'asc'],
                                                    paging: false,
                                                    info: false,
                                                    searching: false,
                                                    columnDefs: [{ targets: -1, orderable: false }],
                                                }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            PIN Code
                                                            <Icon name="chevron-down" />
                                                        </th>
                                                        <th scope="col">
                                                            Start Date
                                                            <Icon name="chevron-down" />
                                                        </th>
                                                        <th scope="col">
                                                            End Date
                                                            <Icon name="chevron-down" />
                                                        </th>
                                                        <th scope="col">
                                                            # Remaining uses
                                                            <Icon name="chevron-down" />
                                                        </th>
                                                        <th className="rui-datatable-empty" />
                                                    </tr>
                                                </thead>
                                                <tbody>{this.render_codes()}</tbody>
                                            </DataTables>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Content.propTypes = {
    app: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    settings: PropTypes.any.isRequired,
    vthing: PropTypes.any.isRequired,

    get_pin_codes: PropTypes.func.isRequired,
    delete_pin_code: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth, app }) => ({
        settings,
        auth,
        app,
    }),
    {
        get_pin_codes,
        delete_pin_code,
    },
)(withRouter(Content));
