/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageContent from '../../components/page-content';
import { connect } from 'react-redux';

import { updateAuth } from '../../actions';

/**
 * Component
 */
class ChatPage extends Component {
    constructor(props) {
        super(props);
        this.props.updateAuth({ limited: true });
    }

    render() {
        return (
            <PageWrap>
                <PageContent nofooter>
                    <AsyncComponent component={() => import('./content')} />
                </PageContent>
            </PageWrap>
        );
    }
}

ChatPage.propTypes = {
    // router-dom
    updateAuth: PropTypes.func.isRequired,
};
export default connect(null, { updateAuth })(ChatPage);
