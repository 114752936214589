import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SettingOdata = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
            <path d="m19 8-5 5-4-4-3 3"></path>
            <g transform="translate(1, 24) scale(0.6)" id="svgGroup" strokeLinecap="round" stroke={color} strokeWidth="1px" fill={color}>
                <text fontFamily="'Open Sans', sans-serif" fontSize="13" fontStyle="normal" fontWeight="normal">
                    OData
                </text>
            </g>
        </svg>
    );
});

SettingOdata.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SettingOdata.displayName = 'AlertTriangle';

export default SettingOdata;
