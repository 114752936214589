import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Infinite = forwardRef(({ color = 'currentColor', size = '240', ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            viewBox="0 0 722.7 383.4"
            style={{ enableBackground: 'new 0 0 722.7 383.4' }}
        >
            <style type="text/css">{'.st0{opacity:0.3;fill:url(#SVGID_1_);enable-background:new;}'}</style>
            <g>
                <g>
                    <g>
                        <linearGradient
                            id="SVGID_1_"
                            gradientUnits="userSpaceOnUse"
                            x1="363.35"
                            y1="-92.5551"
                            x2="363.35"
                            y2="283.0137"
                            gradientTransform="matrix(1 0 0 1 0 100)"
                        >
                            <stop offset="2.233493e-02" style={{ stopColor: '#15C5A8' }} />
                            <stop offset="1" style={{ stopColor: '#15C5A8', stopOpacity: 0 }} />
                        </linearGradient>
                        <path
                            className="st0"
                            d="M566.9,128.7C509.8,53,419,4,316.9,4C144.1,4,4,144.1,4,316.9c0,22.8,2.5,45.1,7.1,66.6H142
				c-7.9-20.7-12.2-43.1-12.2-66.6c0-103.3,83.8-187.1,187.1-187.1S504,213.6,504,316.9c0,23.4-4.3,45.9-12.2,66.6H642
				c-7.9-20.7-12.2-43.1-12.2-66.6c0-69,37.3-129.2,92.9-161.7V18.4C659.9,38.2,605.6,77.3,566.9,128.7z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
});

Infinite.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Infinite.displayName = 'AlertTriangle';

export default Infinite;

{
    /* <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12.01" y2="16"></line>
    <line x1="1" y1="1" x2="23" y2="23"></line>
</svg>; */
}
