import axios from 'axios';
import { makeCancelable } from '../store/utils';

// ######################################################################
//                              Logs
// ######################################################################

export const get_logs_daily = (workspaceName = 'default', options = {}) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                ...options.params,
            },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/logs/${encodeURIComponent(workspaceName)}/daily`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

// ######################################################################
//                              Charts
// ######################################################################

export const get_charts = (workspaceName = 'default', options = {}) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                ...options.params,
            },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/chart`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const create_chart = (workspaceName = 'default', payload, options = {}) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                ...options.params,
            },
        };
        return makeCancelable(
            axios
                .post(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/chart`, payload, config)
                .then(({ data }) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

export const get_chart = (workspaceName = 'default', chart_id, options = {}) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                ...options.params,
            },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/chart/${encodeURIComponent(chart_id)}`, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const update_chart = (workspaceName = 'default', chart, options = {}) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                ...options.params,
            },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/chart/${encodeURIComponent(chart.id)}`, chart, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_chart = (workspaceName = 'default', chart, options = {}) => {
    return (dispatch, getState) => {
        const config = {
            params: {
                ...options.params,
            },
        };
        return makeCancelable(
            axios
                .delete(`/api/1.0/workspaces/${encodeURIComponent(workspaceName)}/chart/${encodeURIComponent(chart.id)}`, chart, config)
                .then(({ data }) => {
                    return data;
                })
                .catch((error) => {
                    throw error;
                }),
        );
    };
};

export const get_odata_logs = (workspace) => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/logs/${encodeURIComponent(workspace)}/odata`).then(({ data }) => {
            return data.logs;
        }),
    );
