/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import classnames from 'classnames/dedupe';
import PropTypes from 'prop-types';
import DatePicker from '../../../components/date-time-picker';
import TouchSpin from '../../../components/touch-spin';
/**
 * Internal Dependencies
 */
import Icon from '../../../components/icon';

import { makeCancelable } from '../../../store/utils';
import { create_pin_code, edit_pin_code, check_pin_code } from '../../../actions';
import InputClipBoard from '../../../components/input-clipboard';

function getRandomInt() {
    return Math.floor(Math.random() * Math.floor(10));
}

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorcode: '',
            value: '',
            start_date: '',
            end_date: '',
            has_count: false,
            count: 0,
            disabled: false,
            edit: false,
            responseError: '',
        };
        this.state.value = this.generateCode();
        if (this.props.code && this.props.code._id) {
            const { value, start, end, count } = this.props.code;
            this.state.value = value;
            this.state.start_date = start ? new Date(start) : '';
            this.state.end_date = end ? new Date(end) : '';
            this.state.has_count = count >= 0;
            if (this.state.has_count) this.state.count = count;
        }
    }
    componentDidMount = () => {
        if (this.props.code && this.props.code._id) return;
        this.checkCode(null, true);
    };

    componentWillUnmount = () => {
        this.time2 && clearTimeout(this.time2);
        this.submitcode && this.submitcode.cancel();
        this.checkcode && this.checkcode.cancel();
    };

    generateCode = () => {
        const code = [];
        for (let c = 8; c > 0; c--) code.push(getRandomInt());
        return code.join('');
    };

    checkCode = (e, retry = false) => {
        e && e.preventDefault();
        this.checkcode && this.checkcode.cancel();
        this.checkcode = makeCancelable(this.props.check_pin_code(this.props.workspace, this.props.vthing.value, this.state.value));
        this.checkcode.promise
            .then(() => {
                if (retry) {
                    this.setState({ value: this.generateCode() }, () => {
                        this.checkCode(false);
                    });
                }
                this.setState({ errorcode: 'Invalid code' });
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                if (error.response.status === 404) this.setState({ errorcode: '' });
                else this.setState({ errorcode: 'Unable to validate code' });
            });
    };

    onCodeChange = ({ target: { value } }) => {
        this.time2 && clearTimeout(this.time2);
        this.setState({ value }, () => {
            this.time2 = setTimeout(() => {
                this.checkCode();
            }, 1000);
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const code = this.props.code && this.props.code._id ? this.props.code : { value: this.state.value };
        code.count = this.state.has_count ? this.state.count : -1;
        code.start = this.state.start_date ? this.state.start_date.getTime() : -1;
        code.end = this.state.end_date ? this.state.end_date.getTime() : -1;

        if (code._id) {
            this.submitcode = makeCancelable(this.props.edit_pin_code(this.props.workspace, this.props.vthing.value, code));
        } else {
            this.submitcode = makeCancelable(this.props.create_pin_code(this.props.workspace, this.props.vthing.value, code));
        }
        this.submitcode.promise
            .then((result) => {
                this.props.onSubmit();
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
            })
            .finally(() => {
                this.submitcode = null;
            });
    };
    onClose = () => {
        this.props.onClose();
    };

    setStartDate = (val) => {
        if (!val) return this.setState({ start_date: '' });
        val = new Date(val);
        if (this.state.end_date && val > this.state.end_date) return;
        this.setState({ start_date: val });
    };
    setEndDate = (val) => {
        val = new Date(val);
        if (this.state.start_date && val < this.state.start_date) return;
        this.setState({ end_date: val });
    };

    render() {
        const { responseError, value, start_date, end_date, has_count, count } = this.state;
        return (
            <Fragment>
                <Modal isOpen={true} toggle={this.onClose} fade className={`rui-snippet rui-snippet-frame ${this.props.className}`}>
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title h2">PIN code</h5>
                            <Button className="close" color="" onClick={this.onClose}>
                                <Icon name="x" />
                            </Button>
                        </div>

                        <ModalBody className="rui-snippet-preview pb-5 mb-15">
                            <FormGroup>
                                <Label for="code">Code</Label>
                                <InputClipBoard id="code" isInvalid={this.state.errorcode} text={value} onChange={this.onCodeChange}/>
                            </FormGroup>
                            <FormGroup className="agora_modal_date">
                                <Label for="start">Start</Label>
                                <br />
                                <DatePicker
                                    id="start"
                                    name="start"
                                    selected={start_date}
                                    onChange={this.setStartDate}
                                    showTimeSelect
                                    placeholder="Select date"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    className="rui-datetimepicker form-control rui-search-input"
                                />
                            </FormGroup>
                            <FormGroup className="agora_modal_date">
                                <Label for="end">End</Label>
                                <br />
                                <DatePicker
                                    id="end"
                                    name="end"
                                    selected={end_date}
                                    onChange={this.setEndDate}
                                    showTimeSelect
                                    placeholder="Select date"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    className="rui-datetimepicker form-control rui-search-input"
                                />
                            </FormGroup>
                            <FormGroup>
                                <CustomInput
                                    className="mb-10"
                                    type="checkbox"
                                    id="has_count"
                                    label="Nbr of use"
                                    checked={has_count}
                                    onChange={(value) => {
                                        this.setState({ has_count: value.target.checked });
                                    }}
                                ></CustomInput>
                                <TouchSpin
                                    className={has_count ? '' : 'agora_disable'}
                                    id="count"
                                    name="count"
                                    min={0}
                                    step={1}
                                    disabled={!has_count}
                                    value={has_count ? count : 0}
                                    onChange={(value) => {
                                        this.setState({ count: value });
                                    }}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.onClose}>
                                Close
                            </Button>{' '}
                            <Button
                                disabled={this.state.errorcode.length != 0}
                                className={classnames('', { 'is-invalid': responseError })}
                                type="submit"
                                color="brand"
                                onClick={this.onSubmit}
                            >
                                Save
                            </Button>
                            {responseError ? <div className="invalid-feedback">{responseError}</div> : ''}
                        </ModalFooter>
                    </form>
                </Modal>
            </Fragment>
        );
    }
}

Content.defaultProps = {
    className: '',
};

Content.propTypes = {
    className: PropTypes.string,
    code: PropTypes.any,
    settings: PropTypes.any.isRequired,
    vthing: PropTypes.any.isRequired,
    workspace: PropTypes.string.isRequired,

    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    create_pin_code: PropTypes.func.isRequired,
    edit_pin_code: PropTypes.func.isRequired,
    check_pin_code: PropTypes.func.isRequired,
};

export default connect(
    ({ settings }) => ({
        settings,
    }),
    { create_pin_code, edit_pin_code, check_pin_code },
)(Content);
