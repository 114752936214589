/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Spinner } from 'reactstrap';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';
import { makeCancelable } from '../../store/utils';
import { get_companies, get_users, delete_user } from '../../actions';
import { get_tokens, delete_token } from '../../actions/tokens';
import * as Roles from '../../types/roles';

import ModalUser from './modalUser';
import ModalToken from './modalToken';
import UsersTable from './UsersTable';
import TokensTable from './TokensTable';

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        const v = Roles._roles[this.props.auth.role] || Roles._default_role;
        this.state = {
            loading: true,
            filter: '',
            users: [],
            tokens: [],
            contentTab: 'users',
            companies: [],
            hashCompanies: {},
            company: {},
            lvl: v.lvl,
            modalOpen: false,
            user: {},
            token: {},
        };
    }
    componentDidMount = () => {
        //if (this.state.lvl >= 2)
        this.getCompanies();
        //else this.getUsers();
    };

    componentWillUnmount = () => {
        this.loadTokens && this.loadTokens.cancel();
        this.delToken && this.delToken.cancel();
        this.loadCompanies && this.loadCompanies.cancel();
        this.loadUsers && this.loadUsers.cancel();
        this.delUser && this.delUser.cancel();
    };

    getCompanies = () => {
        this.loadCompanies = makeCancelable(this.props.get_companies());
        this.loadCompanies.promise
            .then((companies) => {
                this.loadCompanies = null;
                const hashCompanies = {};
                const tmp = companies.map((c) => {
                    hashCompanies[c.id] = c.name;
                    return { value: c.id, label: c.name, type: c.type };
                });
                if (this.state.lvl == 2) tmp.unshift({ value: '', label: 'All users' });
                const company = tmp[0];
                this.setState({ companies: tmp, company, hashCompanies });
                this.getUsers();
            })
            .catch((error) => {
                this.loadCompanies = null;
                if (error.isCanceled === true) return;
            });
    };

    getUsers = () => {
        const { company } = this.state;
        this.setState({ loading: true });
        this.loadUsers = makeCancelable(this.props.get_users(company.value));
        this.loadUsers.promise
            .then((users) => {
                this.loadUsers = null;
                this.setState({ loading: false, users });
            })
            .catch((error) => {
                this.loadUsers = null;
                if (error.isCanceled === true) return;
            });
    };

    handleSearch = (e) => {
        const { value } = e.target;
        this.setState({ filter: value, loading: true }, () => this.setState({ loading: false }));
    };

    editUser = (user) => {
        this.setState({ modalOpen: true, user });
    };

    removeUser = (user) => {
        this.delUser = makeCancelable(this.props.delete_user(user.id));
        this.delUser.promise
            .then(() => {
                this.delUser = null;
                this.getUsers();
            })
            .catch((error) => {
                this.delUser = null;
                if (error.isCanceled === true) return;
                this.setState({ loading: false });
            });
    };

    getTokens = () => {
        const { company } = this.state;
        this.setState({ loading: true });
        this.loadTokens = makeCancelable(this.props.get_tokens(company.value));
        this.loadTokens.promise
            .then((tokens) => {
                this.loadTokens = null;
                this.setState({ loading: false, tokens });
            })
            .catch((error) => {
                this.loadTokens = null;
                if (error.isCanceled === true) return;
                this.setState({ loading: false, tokens: [] });
            });
    };

    editToken = (token) => {
        this.setState({ modalOpen: true, token });
    };

    removeToken = (token) => {
        this.delToken = makeCancelable(this.props.delete_token(token.id));
        this.delToken.promise
            .then(() => {
                this.delToken = null;
                this.getTokens();
            })
            .catch((error) => {
                this.delToken = null;
                if (error.isCanceled === true) return;
                this.setState({ loading: false });
            });
    };

    toggleContent = (e, contentTab) => {
        e.preventDefault();
        if (this.state.contentTab != contentTab) {
            this.setState({ contentTab }, () => {
                this.state.contentTab == 'users' ? this.getUsers() : this.getTokens();
            });
        }
    };
    handleCompany = (company) => {
        if (this.state.company.value !== company.value) {
            if (company.value == '') {
                this.setState({ contentTab: 'users' });
            }
            this.setState({ company }, () => {
                this.state.contentTab == 'users' ? this.getUsers() : this.getTokens();
            });
        }
    };
    handleAddItem = (e) => {
        e.preventDefault();
        this.setState({ modalOpen: true });
    };
    handleClose = (refresh = false) => {
        if (refresh) {
            this.getTokens();
        }
        this.setState({ modalOpen: false, user: {}, token: {} });
    };
    handleSubmitUser = (user) => {
        this.getUsers();
        this.handleClose();
    };
    handleSubmitToken = (token) => {
        this.getTokens();
        this.handleClose();
    };
    render() {
        const { loading, modalOpen, companies, user, company, contentTab, token } = this.state;
        const isAll = company.value === '';
        const displaySidePanel = true; // lvl >= 2 && companies.length > 0;
        return (
            <Fragment>
                {modalOpen && contentTab == 'users' && (
                    <ModalUser company={this.state.company} onClose={this.handleClose} user={user} onSubmit={this.handleSubmitUser} />
                )}
                {modalOpen && contentTab == 'tokens' && (
                    <ModalToken company={this.state.company} onClose={this.handleClose} token={token} onSubmit={this.handleSubmitToken} />
                )}
                <div className="row ">
                    {displaySidePanel && (
                        <div className="col-12 col-lg-2">
                            <div className="card">
                                <div className="card-body pt-20 pr-10 pb-20 pl-10">
                                    <div className="menu-vthing-list">
                                        <ul className="nav menu-vthing-name flex-column mnt-3">
                                            <div>
                                                {companies.map((item) => {
                                                    return (
                                                        <div key={item.value} className="accordion-group">
                                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                            <a
                                                                href="#"
                                                                className={`collapse-link ${item.value === company.value ? 'active' : ''}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleCompany(item);
                                                                }}
                                                            >
                                                                {item.label}
                                                            </a>
                                                            <Collapse isOpen={item.value === company.value}>
                                                                <div className="collapse-content px-5">
                                                                    <li>
                                                                        <Link
                                                                            className={`nav-link px-5 ${this.state.contentTab == 'users' ? 'active' : ''}`}
                                                                            onClick={(e) => this.toggleContent(e, 'users')}
                                                                            to="#"
                                                                        >
                                                                            <Icon name="users" />
                                                                            <span>Users</span>
                                                                        </Link>
                                                                    </li>
                                                                    {item.value !== '' && (
                                                                        <li>
                                                                            <Link
                                                                                className={`nav-link px-5 ${this.state.contentTab == 'tokens' ? 'active' : ''}`}
                                                                                onClick={(e) => this.toggleContent(e, 'tokens')}
                                                                                to="#"
                                                                            >
                                                                                <Icon name="pocket" />
                                                                                <span>Tokens</span>
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`col-12 ${displaySidePanel ? 'col-lg-10' : 'col-lg-12'}`}>
                        <Fragment>
                            <div className="rui-filemanager">
                                <div className="rui-filemanager-head">
                                    <div className="row sm-gap vertical-gap align-items-center">
                                        <div className="col">
                                            <div className="input-group">
                                                <div className="input-group-prepend mnl-3">
                                                    <button type="button" className="btn btn-clean btn-grey-5 mb-0 mnl-10">
                                                        <Icon name="search" />
                                                    </button>
                                                </div>
                                                <input
                                                    type="search"
                                                    className="form-control form-control-clean rui-search-input"
                                                    placeholder="Type to search..."
                                                    onChange={this.handleSearch}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            {!isAll && (
                                                <button type="button" className="btn btn-brand btn-sm btn-uniform btn-round" onClick={this.handleAddItem}>
                                                    <Icon name="plus" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {loading && <Spinner color="secondary" type="grow"></Spinner>}

                                {!loading && contentTab == 'users' && (
                                    <div className="rui-filemanager-content">
                                        <div className="table-responsive-lg">
                                            <UsersTable
                                                filter={this.state.filter}
                                                users={this.state.users}
                                                company={company}
                                                companies={this.state.hashCompanies}
                                                onEdit={this.editUser}
                                                onDelete={this.removeUser}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!loading && contentTab == 'tokens' && (
                                    <div className="rui-filemanager-content">
                                        <div className="table-responsive-lg">
                                            <TokensTable
                                                userlvl={this.state.lvl}
                                                filter={this.state.filter}
                                                tokens={this.state.tokens}
                                                onEdit={this.editToken}
                                                onDelete={this.removeToken}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Content.propTypes = {
    auth: PropTypes.any.isRequired,
    settings: PropTypes.any.isRequired,

    get_companies: PropTypes.func.isRequired,
    get_users: PropTypes.func.isRequired,
    delete_user: PropTypes.func.isRequired,

    get_tokens: PropTypes.func.isRequired,
    delete_token: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth }) => ({
        settings,
        auth,
    }),
    {
        get_companies,
        get_users,
        delete_user,
        get_tokens,
        delete_token,
    },
)(Content);
