/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames/dedupe';
import PropTypes from 'prop-types';
import validator from 'validator';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';

import { makeCancelable } from '../../store/utils';
import { post_user, put_user, reset_user_password } from '../../actions';
import ModalConfirmation from '../../components/modal/modalConfirmation';

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = { login: '', email: '', role: 'companyUser', emailError: '', disabled: false, edit: false, responseError: '', hasPartner: this.props.company.type == "Partner" };
        if (this.props.auth.role === 'superAdmin')
            this.roleOptions = [
                { value: 'companyUser', label: 'Organization Analyst' },
                { value: 'companyAdmin', label: 'Organization Administrator' },
            ];
        else
            this.roleOptions = [
                { value: 'companyUser', label: 'Organization Analyst' },
                { value: 'companyAdmin', label: 'Organization Administrator' },
            ];
            if (this.state.hasPartner && (this.props.auth.role === 'superAdmin' || this.props.auth.role === 'companyPartner'))
                this.roleOptions.push({ value: 'companyPartner', label: 'Partner Administrator' });
        if (this.props.user.id) {
            this.state = { ...this.state, ...this.props.user, edit: true };
            const r = this.roleOptions.find((item) => this.props.user.role == item.value);
            this.defaultRole = r || { value: 'companyUser', label: 'Organization Analyst' };
        } else {
            this.defaultRole = { value: 'companyUser', label: 'Organization Analyst' };
        }
    }

    componentWillUnmount = () => {
        this.addUser && this.addUser.cancel();
        this.updateUser && this.updateUser.cancel();
        this.resetPwd && this.resetPwd.cancel();
        this.errorReset && clearTimeout(this.errorReset);
    };

    checkEmail = () => {
        const { email } = this.state;

        const isValid = email && validator.isEmail(email);

        this.setState({
            emailError: isValid ? '' : 'Invalid email format',
        });

        return isValid;
    };

    handleReset = (ret) => {
        if (!ret) {
            return this.setState({ resetConfirm: false });
        }
        this.errorReset && clearTimeout(this.errorReset);
        this.setState({ loading: false, resetConfirm: false, resetError: '' });
        this.resetPwd = makeCancelable(this.props.reset_user_password(this.state.id));
        this.resetPwd.promise
            .then((u) => {
                this.resetPwd = null;
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.resetPwd = null;
                if (error.isCanceled === true) return;
                this.setState({ loading: false, resetError: 'An error has occurred.' });
                this.errorReset = setTimeout(() => {
                    this.errorReset = null;
                    this.setState({ resetError: '' });
                });
            });
    };

    askResetPassword = (e) => {
        e.preventDefault();
        this.setState({ resetConfirm: true });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const user = (({ id, login, email, role, disabled }) => ({ id, login, email, role, disabled }))(this.state);

        if (user.id) {
            this.updateUser = makeCancelable(this.props.put_user(user));
            this.updateUser.promise
                .then((u) => {
                    this.updateUser = null;
                    this.props.onSubmit(user);
                })
                .catch((error) => {
                    this.updateUser = null;
                    if (error.isCanceled === true) return;
                    let responseError = 'unknown error';
                    if (error.response && error.response.status === 409) responseError = 'user already exists';
                    if (error.response && error.response.status === 400 && typeof error.response.data.type === 'string')
                        responseError = `invalid ${error.response.data.type}`;
                    this.setState({ loading: false, responseError });
                });
        } else {
            if (this.props.auth.role === 'superAdmin' || this.props.auth.role === 'companyPartner') {
                user.company_id = this.props.company.value;
            }
            this.addUser = makeCancelable(this.props.post_user(user));
            this.addUser.promise
                .then((u) => {
                    this.addUser = null;
                    this.props.onSubmit(user);
                })
                .catch((error) => {
                    this.addUser = null;
                    if (error.isCanceled === true) return;
                    let responseError = 'unknown error';
                    if (error.response && error.response.status === 409) responseError = 'user already exists';
                    if (error.response && error.response.status === 400 && typeof error.response.data.type === 'string')
                        responseError = `invalid ${error.response.data.type}`;
                    this.setState({ loading: false, responseError });
                });
        }
    };
    onClose = () => {
        this.props.onClose();
    };
    setLogin = ({ target: { value } }) => {
        this.setState({ login: value, responseError: '' });
    };
    setEmail = ({ target: { value } }) => {
        this.setState({ email: value, responseError: '' }, () => {
            this.checkEmail();
        });
    };
    setRole = ({ value }) => {
        this.setState({ role: value, responseError: '' });
    };
    setDisabled = (e) => {
        this.setState({ disabled: e.target.checked });
    };

    render() {
        const { login, email, role, emailError, edit, disabled, resetConfirm, responseError, resetError } = this.state;
        return (
            <Fragment>
                {resetConfirm && (
                    <ModalConfirmation
                        isOpen
                        title="Confirm reset password"
                        content={`Are you sure to reset "${login}" password?`}
                        onResult={this.handleReset}
                    />
                )}
                <Modal isOpen={true} toggle={this.onClose} fade className={`rui-snippet rui-snippet-frame ${this.props.className}`}>
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title h2">{edit ? 'Update user' : 'Create new user'}</h5>
                            <Button className="close" color="" onClick={this.onClose}>
                                <Icon name="x" />
                            </Button>
                        </div>

                        <ModalBody className="rui-snippet-preview pb-5 mb-15">
                            <FormGroup>
                                <Label for="imageName">User login</Label>
                                <Input type="text" disabled={edit} name="login" id="login" value={login} onChange={this.setLogin} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="imageName">User email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={email}
                                    disabled={edit}
                                    className={classnames('form-control', { 'is-invalid': emailError })}
                                    onBlur={this.checkEmail}
                                    onChange={this.setEmail}
                                />
                                {emailError ? <div className="invalid-feedback">{emailError}</div> : ''}
                            </FormGroup>
                            <FormGroup>
                                <Label for="imageName">User role</Label>
                                <Select
                                    classNamePrefix="agora"
                                    name="role"
                                    id="role"
                                    isClearable={false}
                                    defaultValue={this.defaultRole}
                                    options={this.roleOptions}
                                    onChange={this.setRole}
                                    noOptionsMessage={() => 'No roles...'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <CustomInput type="checkbox" id="disabled" checked={disabled} label="Disable user" onChange={this.setDisabled} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            {edit && !this.props.user.disabled && !this.props.user.idp && (
                                <Fragment>
                                    <Button color="warning"
                                        className={classnames('btn-user-reset', { 'is-invalid': resetError })}
                                        onClick={this.askResetPassword}>
                                        Reset password
                                    </Button>{' '}
                                </Fragment>
                            )}
                            <Button color="secondary" onClick={this.onClose}>
                                Close
                            </Button>{' '}
                            <Button
                                className={classnames('', { 'is-invalid': responseError })}
                                type="submit"
                                color="brand"
                                disabled={!!emailError.length || !login.length || !email.length || !role.length}
                                onClick={this.onSubmit}
                            >
                                {edit ? 'Update' : 'Create'}
                            </Button>
                            {responseError ? <div className="invalid-feedback">{responseError}</div> : ''}
                            {resetError ? <div className="invalid-feedback">{resetError}</div> : ''}
                        </ModalFooter>
                    </form>
                </Modal>
            </Fragment>
        );
    }
}

Content.defaultProps = {
    className: '',
};

Content.propTypes = {
    className: PropTypes.string,
    settings: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    company: PropTypes.object.isRequired,
    user: PropTypes.any.isRequired,

    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    post_user: PropTypes.func.isRequired,
    put_user: PropTypes.func.isRequired,
    reset_user_password: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth }) => ({
        settings,
        auth,
    }),
    { post_user, put_user, reset_user_password },
)(Content);
