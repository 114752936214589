/**
 * External Dependencies
 */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Internal Dependencies
 */
import SectionMenu from '../../tree';
import AsyncComponent from '../../async-component';
import { AgoraWorkspaceContext, components, eSection } from '.';

/*=============================================
=            Control declaration              =
=============================================*/
const WorkspaceConfigurationBody = ({ children }) => {
    /*=============================================
    =            State declaration                =
    =============================================*/
    const { setCustomAction, setOnSave, setCanSave } = useContext(AgoraWorkspaceContext);
    const [activeTab, setActiveTab] = useState(eSection.DEFAULT);
    const [component, setComponent] = useState(() => components[activeTab].component);

    const menu = useMemo(() => {
        const list = [];
        for (const [section, prop] of Object.entries(components)) {
            list.push({
                key: section,
                title: (node) => {
                    return (
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab(node.key);
                            }}
                        >
                            {prop.label}
                        </Link>
                    );
                },
                className: 'rui-rc-tree-root',
                isLeaf: true,
            });
        }
        return list;
    }, []);
    /*=============================================
    =            Actions section                  =
    =============================================*/
    useEffect(() => {
        setCustomAction(<></>);
        setOnSave(() => void 0);
        setCanSave(false);
        setComponent(() => components[activeTab]?.component);
    }, [activeTab, setCustomAction, setOnSave, setCanSave]);

    /*=============================================
    =            Render section                   =
    =============================================*/
    return useMemo(() => {
        return (
            <div className="row vertical-gap in-modal xs-gap">
                <div className="col-12 col-lg-2">
                    <div className="card">
                        <div className="card-body pt-20 pr-10 pb-20 pl-10">
                            <SectionMenu treeData={menu} selected={activeTab}></SectionMenu>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-10">
                    <div className="card">
                        <div className="card-body" style={{ minHeight: '450px' }}>
                            {component ? <AsyncComponent component={component} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [activeTab, component, menu]);
};

/*=============================================
=            props declaration                =
=============================================*/
WorkspaceConfigurationBody.defaultProps = {
    children: <></>,
};

WorkspaceConfigurationBody.propTypes = {
    children: PropTypes.any.isRequired,
};

export default connect(null, null)(WorkspaceConfigurationBody);
