/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import Icon from '../../components/icon';
import Tabs from '../../components/tabs';

import withAcl, { TYPE_PERMISSION } from '../../components/with-acl';

const tabs = [
    { icon: 'database', label: 'RAG', name: 'rag' },
    { icon: 'book', label: 'Class', name: 'class' },
    { icon: 'message-square', label: 'Intents', name: 'intent' },
    { icon: 'list', label: 'Corpus', name: 'corpus' },
];
const components = {
    corpus: { component: () => import('./corpus/CorpusEditor') },
    intent: { component: () => import('./intent/IntentEditor') },
    class: { component: () => import('./class/GaelClassEditor') },
    rag: { component: () => import('./RAG/RagContext') },
};

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }
    componentDidMount = () => {};
    componentDidUpdate = (prevProps) => {
        window.jQuery(window).trigger('rui-ajax-update-tab');
    };

    componentWillUnmount = () => {};

    toggleTabs = (event, selected) => {
        event.preventDefault();
        if (this.props.match.params.tab !== selected.name) {
            const pathname = `/nlp/${encodeURIComponent(selected.name)}`;
            this.props.history.replace({ pathname, search: this.props.location.search });
        }
    };

    shouldComponentUpdate(nextProps) {
        if (this.props.match.params.tab == nextProps.match.params.tab && this.props.location.search == nextProps.location.search) {
            return false;
        }
        return true;
    }

    render() {
        const { loading } = this.state;
        const { tab: active_tab } = this.props.match.params;
        if (loading) return <Spinner color="secondary" type="grow"></Spinner>;
        return (
            <Fragment>
                <Tabs sliding>
                    {tabs.map((item) => {
                        if (item.name == 'rag' && this.props.acl.permissions.limited) {
                            return null;
                        }
                        const pathname = `/nlp/${encodeURIComponent(item.name)}`;
                        return (
                            <Tabs.NavItem
                                href={`#${pathname}${this.props.location.search}`}
                                key={item.name}
                                isActive={active_tab === item.name}
                                onClick={(e) => {
                                    this.toggleTabs(e, item);
                                }}
                            >
                                <Icon className="mr-5" name={item.icon} />

                                {item.label}
                            </Tabs.NavItem>
                        );
                    })}
                </Tabs>
                <Tabs.Content activeTab={active_tab}>
                    <Tabs.Pane tabId={active_tab}>
                        <AsyncComponent component={components[active_tab].component} />
                    </Tabs.Pane>
                </Tabs.Content>
            </Fragment>
        );
    }
}
Content.propTypes = {
    // router-dom
    location: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
    match: PropTypes.object.isRequired,
    acl: PropTypes.object.isRequired
};

export default withRouter(withAcl(Content, TYPE_PERMISSION.NLP_RAG));
