import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ICU from 'i18next-icu';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const backend = new Backend(null, { queryStringParams: { v: '1.0.0' } });
i18n.use(ICU)
    .use(LanguageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        ns: [],
        defaultNS: 'common',
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

i18n.changeLanguage("en");
export default i18n;
