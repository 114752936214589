import './style.scss';
/**
 * External Dependencies
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tree from 'rc-tree';
/**
 * Internal Dependencies
 */
import Icon from '../icon';

/**
 * Component
 */

const getFileIcon = (data) => {
    if (data.data?.feathIcon) {
        return <Icon name={data.data?.feathIcon} />;
    }
    // if (data.data && data.data.children) {
    //     return <Icon name={data.expanded ? 'chevron-right' : 'plus'} />;
    // }
    return <Icon vendor="agora" name="dot" />;
};

function TreeMenu({ onSelect, treeData, filterNode, selected, showLine }) {
    return (
        <Fragment>
            <Tree
                className="rui-rc-tree"
                showLine={showLine}
                checkable={false}
                selectable={true}
                checkStrictly={true}
                selectedKeys={[selected]}
                expandedKeys={[selected]}
                autoExpandParent
                onSelect={onSelect}
                filterTreeNode={(node) => {
                    if (filterNode) {
                        return filterNode(node);
                    }
                    return false;
                }}
                treeData={treeData}
                icon={getFileIcon}
            />
        </Fragment>
    );
}

/*=============================================
=            props declaration                =
=============================================*/
TreeMenu.defaultProps = {
    showLine: false,
};

TreeMenu.propTypes = {
    showLine: PropTypes.bool,
    selected: PropTypes.string.isRequired,
    treeData: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    filterNode: PropTypes.func,
};

export default TreeMenu;
