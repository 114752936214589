/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/dedupe';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

/**
 * Internal Dependencies
 */
import { initTabsSliding } from '../../../../common-assets/js/rootui-parts/initTabsSliding';

window.RootUI.initTabsSliding = initTabsSliding;
window.RootUI.initTabsSliding();

/**
 * Component
 */
class Tabs extends Component {
    componentDidMount() {
        window.jQuery(window).trigger('rui-ajax-loaded');
    }

    render() {
        const {
            sliding = false,
            pills = false,
            children,
            className,
        } = this.props;

        return (
            <Nav className={ classnames( pills ? 'nav-pills' : 'nav-tabs', sliding ? 'rui-tabs-sliding' : '', className ) }>
                { children }
            </Nav>
        );
    }
}

Tabs.defaultProps = {
    className: '',
};

Tabs.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    pills: PropTypes.bool,
    sliding: PropTypes.bool,
};
/**
 * Component Item
 */
class TabsNavItem extends Component {
    render() {
        const {
            children,
            isActive,
            className,
            onClick,
            href,
        } = this.props;

        return (
            <NavItem>
                <NavLink
                    innerRef={(link) => this.props.innerRef(link)}
                    className={ classnames( { active: isActive }, 'rui-tabs-link', className ) }
                    onClick={ onClick }
                    href={href}
                >
                    { children }
                </NavLink>
            </NavItem>
        );
    }
}

TabsNavItem.defaultProps = {
    className: '',
    href: '#',
    innerRef: ()=> {},
};

TabsNavItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    innerRef: PropTypes.func.isRequired,
};

Tabs.NavItem = TabsNavItem;

Tabs.Content = TabContent;

Tabs.Pane = TabPane;

export default Tabs;
