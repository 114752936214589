/**
 * Internal Dependencies
 */
// Auth
import AuthSignIn from './AuthSignIn';
import CustomSignIn from './CustomSignIn';

// Start
import Dashboard from './Dashboard';

// Apps
import Messenger from './Messenger';
import Chat from './Chat';

import Users from './UsersManagement';
import Vthings from './vthings';
import Gael from './Gael';

import Logs from './logs';
import Companies from './CompaniesManagement';

import Tasks from './Tasks';
import Admin from './Admin';

export default {
    '/sign-in': { component: AuthSignIn, always: true },
    '/authenticate': { alias: ['/authenticate/:mode?'], component: CustomSignIn, always: true },

    '/': Dashboard,

    '/users': { alias: ['/users', '/users/access_users', '/users/access_codes'], component: Users, title: 'Users - Admin Agora Console' },
    '/store': { component: Vthings, title: 'Store - Admin Agora Console' },
    '/logs': { component: Logs, title: 'Verbatims - Admin Agora Console' },
    '/workflows': { alias: ['/workflows', '/workflows/scripts', '/workflows/events'], component: Tasks, title: 'Workflows - Admin Agora Console' },
    // Apps
    '/messenger': { component: Messenger, title: 'Conversation - Admin Agora Console' },
    '/organizations': { component: Companies, title: 'Organizations - Admin Agora Console' },
    '/nlp': {
        alias: [ '/nlp/:tab(class|intent|corpus)/:name?/:view?', '/nlp/:tab(rag)/:ragid?/:docid?/:view?'],
        component: Gael,
        title: 'NL Studio - Admin Agora Console',
    },
    '/back': { alias: ['/back/:tab?'], component: Admin, title: 'Settings - Admin Agora Console' },
    // iframe mode
    '/chat': { alias: ['/chat/:device?'], component: Chat, title: 'Conversation - Admin Agora Console', webview: true, skipcgu: true },
};
