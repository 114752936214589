/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, HashRouter, Route } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { /* CSSTransition, */ TransitionGroup } from 'react-transition-group';
import thunk from 'redux-thunk';
import { Shortcuts, ShortcutManager } from 'react-shortcuts';
import Cookies from 'js-cookie';

/**
 * Internal Dependencies
 */
import './components/animated-route';
import reducers from './reducers';
import Routes from './Routes';
import PageYaybar from './components/page-yaybar';
import PageNavbar from './components/page-navbar';
import PageToasts from './components/page-toasts';

import {
    updateAuth as actionUpdateAuth,
    getMe as actionGetMe,
    flushWorkspaces,
    get_workspaces as actionGetWorkspaces,
    updateInit,
    get_vthings,
} from './actions';

import { get_workspace_settings } from './actions/users';

import NetworkService from './store/network-service';
import { makeCancelable } from './store/utils';
import querystring from 'querystring';
import url from 'url';
import shortcutsObserver, { keymap_app } from './utils/shortcuts';
import './utils/datatable-theme';
import './utils/i18n'; // initialisation de i18next

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

const $html = window.jQuery('html');
const $body = window.jQuery('body');

const shortcutManager = new ShortcutManager(keymap_app);

/**
 * Component PageWrap
 */
class PageWrap extends Component {
    constructor(props) {
        super(props);

        const queryData = url.parse(window.location.href, true).query;
        if (queryData?.redirect) {
            window.location.href = '/#' + queryData?.redirect;
        }
        this.maybeCheckAuth = this.maybeCheckAuth.bind(this);
        this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind(this);
        this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind(this);
        this.unlisten = this.props.history.listen((location, action) => {
            if (!this.isSignPage(location.pathname) && this.props.app.initialized) {
                let query = querystring.parse(location.search.replace(/^\?/g, ''));
                if (!query.ws || query.ws.length == 0) {
                    query = this.updatePath(location, query);
                } else {
                    if (query?.ws !== this.props.app.query.ws) {
                        this.props.get_workspace_settings(query?.ws);
                        this.props.get_vthings(query?.ws);
                    }
                    this.props.updateInit({ query });
                }
            }
        });
    }

    componentDidMount() {
        window.addEventListener('focus', this.onFocus);
        if (this.props.location.pathname !== '/authenticate') {
            let referrer = null;
            if (!this.isSignPage()) {
                referrer = this.props.location.pathname + this.props.location.search;
            }
            const queryData = url.parse(window.location.href, true).query;
            if (!queryData?.redirect) {
//            if (!this.props.location.pathname.startsWith('/chat/')) {
                this.getme = makeCancelable(this.props.getMe());
                this.getme.promise.then((islogged) => {
                    if (!islogged && referrer) {
                        this.props.updateAuth({
                            referrer,
                        });
                    }
                });
            }
            this.maybeCheckAuth();
        }
        this.maybeUpdateGlobalSettings();
    }

    componentDidUpdate(prevProps) {
        this.maybeCheckAuth(prevProps);
        this.maybeLoadResources(prevProps);
        this.maybeUpdateGlobalSettings(prevProps);
        this.maybeScrollPageToTop(prevProps);
    }

    updatePath = (location, query) => {
        query = { ...this.props.app.query, ...query };
        if (!query.ws) return;
        const newpath = `${location.pathname}?${querystring.stringify(query)}`;
        this.props.history.replace(newpath);
        return query;
    };
    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener('focus', this.onFocus);
    }

    onFocus = () => {
        if (this.props.auth.token && this.props.app.query?.ws) {
            localStorage.setItem('ws', this.props.app.query.ws);
        }
    };

    isSignPage(check) {
        if (!check) {
            check = this.props.location.pathname;
            // check = window.location.hash.replace(/^#/g, '');
        }
        return check === '/sign-in' || check === '/sign-up' || check.startsWith('/authenticate');
    }

    maybeLoadResources = (prevProps) => {
        const { auth } = this.props;

        if (prevProps && auth.token !== prevProps.auth.token && auth.token) {
            if (localStorage.getItem('x-token')?.startsWith('vtm:')) {
                const ws = Cookies.get('workspace');
                ws && this.props.get_workspace_settings(ws);
                this.props.updateInit({ initialized: true, query: { ws } });
                return;
            }
            this.getWS = makeCancelable(this.props.get_workspaces());
            this.getWS.promise
                .then(() => {
                    let query = location.hash.indexOf('?') >= 0 ? querystring.parse(location.hash.replace(/^.*\?/g, '')) : this.props.app.query;
                    if (this.props.workspaces.length > 0) {
                        let ws = query && query.ws ? this.props.workspaces.find((item) => item.workspace == query.ws) : null;
                        if (!ws) {
                            const local = localStorage.getItem('ws');
                            ws = local ? this.props.workspaces.find((item) => item.workspace == local) : null;
                            if (!ws) {
                                ws = this.props.workspaces[0];
                            }
                            query.ws = ws.workspace;
                        }
                    }
                    query = this.updatePath(this.props.location, query);
                    query?.ws && localStorage.setItem('ws', query.ws);
                    if (query?.ws !== this.props.app.query.ws) {
                        this.props.get_vthings(query?.ws);
                    }
                    query?.ws && this.props.get_workspace_settings(query?.ws);
                    this.props.updateInit({ initialized: true, query });
                })
                .catch(() => false);
        } else if (prevProps && auth.token !== prevProps.auth.token && !auth.token) {
            if (this.getWS) this.getWS.cancel();
            this.getWS = null;
            this.props.flushWorkspaces();
            this.props.updateInit({ initialized: false, query: {} });
        }
    };
    maybeCheckAuth(prevProps) {
        const { auth, updateAuth, history } = this.props;

        let newRedirect = false;

        const referrerUrl = auth.referrer && !this.isSignPage(auth.referrer) ? auth.referrer : '/';

        // Redirect from Auth page.
        if (this.isSignPage() && auth.token) {
            newRedirect = referrerUrl;

            // Redirect to Auth page.
        } else if (!this.isSignPage() && !auth.token) {
            newRedirect = '/sign-in';

            // Check if use logged out or logged in.
        } else if (prevProps && auth.token !== prevProps.auth.token) {
            newRedirect = auth.token ? false : '/sign-in';
        }

        // Redirect.
        if (newRedirect) {
            updateAuth({
                referrer: window.location.hash.replace(/^#/g, ''),
            });
            history.push(newRedirect);
        }
    }

    maybeUpdateGlobalSettings(prevProps) {
        const { settings } = this.props;
        // night mode.
        if (prevProps && prevProps.settings.night_mode !== settings.night_mode) {
            if (settings.night_mode) {
                localStorage.setItem('settings', JSON.stringify({ night_mode: true }));
                $html.addClass('rui-night-mode');
                import('./style-night.scss');
            } else {
                localStorage.setItem('settings', JSON.stringify({ night_mode: false }));
                $html.removeClass('rui-night-mode');
            }
        }
        if (!prevProps && settings.night_mode) {
            $html.addClass('rui-night-mode');
            import('./style-night.scss');
        }
        // spitlight mode.
        if (prevProps && prevProps.settings.spotlight_mode !== settings.spotlight_mode) {
            if (settings.spotlight_mode) {
                $body.addClass('rui-spotlightmode');
            } else {
                $body.removeClass('rui-spotlightmode');
            }
        }
        if (!prevProps && settings.spotlight_mode) {
            $body.addClass('rui-spotlightmode');
        }

        // section lines.
        if (prevProps && prevProps.settings.show_section_lines !== settings.show_section_lines) {
            if (settings.show_section_lines) {
                $body.addClass('rui-section-lines');
            } else {
                $body.removeClass('rui-section-lines');
            }
        }
        if (!prevProps && settings.show_section_lines) {
            $body.addClass('rui-section-lines');
        }

        // sidebar small.
        if (prevProps && prevProps.settings.sidebar_small !== settings.sidebar_small) {
            if (settings.sidebar_small) {
                $body.addClass('yay-hide');
            } else {
                $body.removeClass('yay-hide');
            }
        }
        if (!prevProps && settings.sidebar_small) {
            $body.addClass('yay-hide');
        }
    }

    maybeScrollPageToTop(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    render() {
        const { auth, location, app } = this.props;

        return (
            <TransitionGroup>
                <Shortcuts name="AGORA_KEYS" isolate handler={shortcutsObserver.notify}>
                    <PageToasts />
                    <Route>
                        {/* // todo: voir pour mettre un cookie */}
                        {auth.token && window.parent == window && !auth.limited ? (
                            <>
                                <Route component={PageYaybar} />
                                <Route component={PageNavbar} />
                            </>
                        ) : (
                            ''
                        )}
                        {!!app.settings.theme?.css && <style>{app.settings.theme.css}</style>}
                    </Route>
                    <Routes location={location} />
                    {/* <CSSTransition key={location.pathname} timeout={300} classNames="rui-router-transition" unmountOnExit>
                </CSSTransition> */}
                </Shortcuts>
            </TransitionGroup>
        );
    }
}
PageWrap.propTypes = {
    history: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    workspaces: PropTypes.array.isRequired,
    settings: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,

    updateAuth: PropTypes.func.isRequired,
    getMe: PropTypes.func.isRequired,
    get_workspaces: PropTypes.func.isRequired,
    flushWorkspaces: PropTypes.func.isRequired,
    updateInit: PropTypes.func.isRequired,
    get_vthings: PropTypes.func.isRequired,
    get_workspace_settings: PropTypes.func.isRequired,
};

const PageWrapWithState = connect(
    ({ auth, settings, workspaces, app }) => ({
        auth,
        workspaces,
        settings,
        app,
    }),
    { updateAuth: actionUpdateAuth, getMe: actionGetMe, get_workspaces: actionGetWorkspaces, flushWorkspaces, updateInit, get_vthings, get_workspace_settings },
)(withRouter(PageWrap));

/**
 * Component App
 */
class App extends Component {
    constructor(props) {
        super(props);

        // create redux store.
        this.store = createStoreWithMiddleware(reducers);
        NetworkService.setupInterceptors(this.store);
    }
    getChildContext() {
        return { shortcuts: shortcutManager };
    }
    render() {
        return (
            <Provider store={this.store}>
                <HashRouter>
                    <PageWrapWithState />
                </HashRouter>
            </Provider>
        );
    }
}

App.childContextTypes = {
    shortcuts: PropTypes.object.isRequired,
};
export default App;
