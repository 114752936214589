const toHSL = (rgb, alpha = 1) => {
    const r = rgb[0] / 255;
    const g = rgb[1] / 255;
    const b = rgb[2] / 255;
    const a = alpha;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h;
    let s;
    const l = (max + min) / 2;
    const d = max - min;

    if (max === min) {
        h = s = 0;
    } else {
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    return { h: h * 360, s, l, a };
};

function clamp(val) {
    return Math.min(1, Math.max(0, val));
}

const hsla = function (h, s, l, a) {
    h = (h % 360) / 360;
    s = clamp(s);
    l = clamp(l);
    a = clamp(a);

    const m2 = l <= 0.5 ? l * (s + 1) : l + s - l * s;
    const m1 = l * 2 - m2;

    function hue(h) {
        h = h < 0 ? h + 1 : h > 1 ? h - 1 : h;
        if (h * 6 < 1) {
            return m1 + (m2 - m1) * h * 6;
        } else if (h * 2 < 1) {
            return m2;
        } else if (h * 3 < 2) {
            return m1 + (m2 - m1) * (2 / 3 - h) * 6;
        } else {
            return m1;
        }
    }

    const rgb = [hue(h + 1 / 3) * 255, hue(h) * 255, hue(h - 1 / 3) * 255];
    return rgb;
};

function clamp2(v, max) {
    return Math.min(Math.max(v, 0), max);
}

function toHex(v) {
    return `#${v
        .map(function (c) {
            c = clamp2(Math.round(c), 255);
            return (c < 16 ? '0' : '') + c.toString(16);
        })
        .join('')}`;
}

const darken = (c, a = 0) => {
    const hsl = toHSL(c);

    hsl.l -= a / 100;
    hsl.l = Math.min(1, Math.max(0, hsl.l));
    return hsla(hsl.h, hsl.s, hsl.l, 1);
};

export const toRGB = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return [r, g, b];
};

export const update_css = (color) => {
    if (/^#?[a-f0-9]{6}$/i.exec(color)) {
        document.documentElement.style.setProperty('--agora-brand', color);
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        document.documentElement.style.setProperty('--agora-brand-dk-7-5', toHex(darken([r, g, b], 7.5)));
        document.documentElement.style.setProperty('--agora-brand-dk-12-5', toHex(darken([r, g, b], 12.5)));
        document.documentElement.style.setProperty('--agora-brand-dk-05', toHex(darken([r, g, b], 5)));
        document.documentElement.style.setProperty('--agora-brand-dk-10', toHex(darken([r, g, b], 10)));
        document.documentElement.style.setProperty('--agora-brand-dk-20', toHex(darken([r, g, b], 20)));
        document.documentElement.style.setProperty('--agora-brand-lg-05', toHex(darken([r, g, b], -5)));
        document.documentElement.style.setProperty('--agora-brand-lg-30', toHex(darken([r, g, b], -30)));
        document.documentElement.style.setProperty('--agora-brand-r', r);
        document.documentElement.style.setProperty('--agora-brand-g', g);
        document.documentElement.style.setProperty('--agora-brand-b', b);
        return true;
    } else {
        return false;
    }
};
