let observers = [];

function emitChange(action, obj = {}) {
    observers.forEach((o) => o && o(action, obj));
}

export const UI_NOCODE = {
    TOGGLE_CTX: 'ctx_change',
    UPDATE_PROD: 'update',
    REFRESH: 'refresh',
};

export function emitUpdateProduction(script) {
    emitChange(UI_NOCODE.UPDATE_PROD, script);
}

export function emitSwitchContext(context) {
    emitChange(UI_NOCODE.TOGGLE_CTX, context);
}

export function emitRefreshTasks() {
    emitChange(UI_NOCODE.REFRESH);
}

export function observe(o) {
    observers.push(o);
    return () => {
        observers = observers.filter((t) => t !== o);
    };
}
