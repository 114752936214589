/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames/dedupe';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';
import { makeCancelable } from '../../store/utils';
import { create_chart, update_chart, delete_chart } from '../../actions/logs';
import { moveCaretAtEnd } from '../../tools';

const MySwal = withReactContent(Swal);

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);

        this.$focus = React.createRef();

        if (this.props.item) {
            this.state = { name: this.props.item.name, title: this.props.item.title, edit: true };
        } else {
            this.state = { name: '', title: '', edit: false };
        }
        this.state.pending = false;
        this.state.errorMsg = '';
    }

    /*=============================================
    =            Section component                =
    =============================================*/

    componentWillUnmount = () => {
        this.submitCancelable?.cancel();
        this.deleteCancelable?.cancel();
    };

    /*=============================================
    =            Section methods                  =
    =============================================*/
    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.pending) return;
        this.setState({ pending: true, errorMsg: '' });
        const { edit, name } = this.state;
        let { title } = this.state;
        if (!title.length) title = name;
        if (edit) {
            this.submitCancelable = makeCancelable(this.props.update_chart(this.props.app.query.ws, { id: this.props.item.id, name, title }));
        } else {
            this.submitCancelable = this.props.create_chart(this.props.app.query.ws, { name, title });
        }

        this.submitCancelable.promise
            .then((result) => {
                this.props.onSubmit(edit ? { ...this.props.item, name, title } : { id: result.data.id, name, title });
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.setState({ pending: false, errorMsg: 'Invalid name' });
            });
    };
    onClose = () => {
        if (this.state.pending) return;
        this.props.onClose();
    };
    onDelete = () => {
        MySwal.fire({
            position: 'top',
            title: `Are you sure to delete "${this.props.item.name}"`,
            icon: 'warning',
            html: "You won't be able to revert this!",
            showCancelButton: true,
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteCancelable = this.props.delete_chart(this.props.app.query.ws, { id: this.props.item.id });
                this.deleteCancelable.promise
                    .then((result) => {
                        this.props.onDelete({ id: this.props.item.id });
                    })
                    .catch((error) => {
                        if (error.isCanceled === true) return;
                    });
            }
        });
    };

    setName = ({ target: { value } }) => {
        this.setState({ name: value, errorMsg: '' });
    };

    setTitle = ({ target: { value } }) => {
        this.setState({ title: value });
    };

    /*=============================================
    =            Section render                   =
    =============================================*/
    render() {
        const { name, edit, title, errorMsg } = this.state;
        return (
            <Fragment>
                <Modal
                    isOpen={true}
                    toggle={this.onClose}
                    fade
                    className={`rui-snippet rui-snippet-frame ${this.props.className}`}
                    onOpened={() => {
                        this.$focus?.current?.focus();
                    }}
                >
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title h2">Chart properties</h5>
                            <Button className="close" color="" onClick={this.onClose}>
                                <Icon name="x" />
                            </Button>
                        </div>

                        <ModalBody className="rui-snippet-preview pb-5 mb-15">
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                    innerRef={(elem) => {
                                        if (!edit) this.$focus.current = elem;
                                    }}
                                    onFocus={moveCaretAtEnd}
                                    className={classnames('form-control', { 'is-invalid': errorMsg })}
                                    placeholder="Name"
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={this.setName}
                                />
                                {errorMsg.length > 0 ? <div className="invalid-feedback">{errorMsg}</div> : ''}
                            </FormGroup>
                            <FormGroup>
                                <Label for="title">Title</Label>
                                <Input
                                    onFocus={moveCaretAtEnd}
                                    className="form-control"
                                    placeholder="Title"
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={title}
                                    onChange={this.setTitle}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            {edit && (
                                <Button style={{ marginRight: 'auto' }} color="danger" onClick={this.onDelete}>
                                    Delete
                                </Button>
                            )}
                            <Button color="secondary" onClick={this.onClose}>
                                Close
                            </Button>
                            <Button type="submit" color="brand" disabled={!name.length || !!errorMsg.length} onClick={this.onSubmit}>
                                {edit ? 'Update' : 'Create'}
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </Fragment>
        );
    }
}

Content.defaultProps = {
    className: '',
    onDelete: () => void 0,
};

Content.propTypes = {
    className: PropTypes.string,
    app: PropTypes.any.isRequired,
    item: PropTypes.any,

    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    create_chart: PropTypes.func.isRequired,
    update_chart: PropTypes.func.isRequired,
    delete_chart: PropTypes.func.isRequired,
};

export default connect(
    ({ app }) => ({
        app,
    }),
    { create_chart, update_chart, delete_chart },
)(Content);
