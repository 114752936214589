/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


/**
 * Internal Dependencies
 */
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Tabs from '../../components/tabs';

import Console from './content';
import VThing from './content2';

/**
 * Component
 */
class UsersManagementPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.location.pathname.startsWith('/users/access_') ? 'access' : 'console',
        };

        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(e, name) {
        e.preventDefault();
        if (this.state.activeTab == name) return;
        name === 'console' ? this.props.history.push(`/users${this.props.location.search}`) : this.props.history.push(`/users/access_users${this.props.location.search}`);
        this.setState({
            activeTab: name,
        });
    }

    render() {
        const { activeTab } = this.state;
        return (
            <PageWrap>
                <PageTitle>
                    <h1>Users Management</h1>
                </PageTitle>
                <PageContent>
                    <Tabs sliding>
                        <Tabs.NavItem isActive={activeTab === 'console'} onClick={(e) => this.toggleTab(e, 'console')}>
                            Console Users Management
                        </Tabs.NavItem>
                        <Tabs.NavItem isActive={activeTab === 'access'} onClick={(e) => this.toggleTab(e, 'access')}>
                            Guest Management
                        </Tabs.NavItem>
                    </Tabs>
                    <Tabs.Content activeTab={activeTab}>
                        <Tabs.Pane tabId="console">{activeTab === 'console' && <Console />}</Tabs.Pane>
                        <Tabs.Pane tabId="access">{activeTab === 'access' && <VThing />}</Tabs.Pane>
                    </Tabs.Content>
                </PageContent>
            </PageWrap>
        );
    }
}

UsersManagementPage.propTypes = {
    history: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,
};
export default withRouter(UsersManagementPage);
