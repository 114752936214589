/**
 * External Dependencies
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';
import { Input, InputGroup, InputGroupAddon, PopoverBody, UncontrolledPopover } from 'reactstrap';
import classNames from 'classnames';

/**
 * Component
 */
const InputClipBoard = ({ id, text, tips, tipsLabel, isInvalid, onChange }) => {
    const buttonRef = useRef();
    const buttonTimeout = useRef();
    const [infoButton, setInfoButton] = useState(false);
    const [btnName] = useState(`btn-clip-${id}`);
    const [clipboard] = useState(ClipboardJS.isSupported());

    const onCopySuccess = useCallback((e) => {
        buttonTimeout.current && clearTimeout(buttonTimeout.current);
        setInfoButton(true);
        buttonTimeout.current = setTimeout(() => {
            setInfoButton(false);
        }, 1000);
        e.clearSelection();
        return false;
    }, []);

    useEffect(() => {
        const _clipboardAction = new ClipboardJS(buttonRef.current);
        _clipboardAction.on('success', onCopySuccess);
        return () => {
            _clipboardAction.off('success', onCopySuccess);
            _clipboardAction.destroy();
        };
    }, [onCopySuccess]);

    useEffect(() => {
        // return function will be call like willUnmount
        return () => buttonTimeout.current && clearTimeout(buttonTimeout.current);
    }, []);

    return (
        <>
            <InputGroup className="mb-3">
                <Input
                    className={classNames('', { 'is-valid': infoButton, 'is-invalid': !!isInvalid })}
                    type="text"
                    disabled={!onChange}
                    name={id}
                    id={id}
                    value={text}
                    onChange={(e) => {
                        if (onChange) onChange(e);
                    }}
                />
                {clipboard && (
                    <InputGroupAddon addonType="append">
                        <button
                            data-clipboard-text={text}
                            id={btnName}
                            type="button"
                            className="btn btn-brand"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                            ref={buttonRef}
                        >
                            <Icon name={infoButton ? 'check' : 'copy'} />
                        </button>
                        <UncontrolledPopover placement="top" target={btnName} isOpen={tips && infoButton}>
                            <PopoverBody className="py-5">{tipsLabel}</PopoverBody>
                        </UncontrolledPopover>
                    </InputGroupAddon>
                )}
                {isInvalid ? <div className="invalid-feedback">{isInvalid}</div> : ''}
            </InputGroup>
        </>
    );
};

InputClipBoard.defaultProps = {
    tips: true,
    tipsLabel: 'Copied!',
    isInvalid: '',
};
InputClipBoard.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    tips: PropTypes.bool,
    tipsLabel: PropTypes.string,
    isInvalid: PropTypes.string,
    onChange: PropTypes.func,
};

export default InputClipBoard;
