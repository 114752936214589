// ######################################################################
//                              IMPORT
// ######################################################################
import axios from 'axios';
import { makeCancelable } from '../store/utils';

export const VALIDATE_CGU = 'VALIDATE_CGU';
export const UPDATE_WORKSPACE_SETTINGS = 'UPDATE_WORKSPACE_SETTINGS';
const updateSettings = (workspace, settings) => ({
    type: UPDATE_WORKSPACE_SETTINGS,
    workspace,
    settings,
});
// ######################################################################
//                              settings
// ######################################################################
export const get_workspace_settings = (workspace) => {
    return (dispatch /*, getState*/) => {
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/settings/${encodeURIComponent(workspace)}`)
                .then((response) => {
                    dispatch(updateSettings(workspace, response.data.data));
                    resolve(response.data.data);
                })
                .catch((error) => {
                    dispatch(
                        updateSettings(workspace, {
                            open_scripts: [],
                            active_tab: '',
                        }),
                    );
                    reject(error);
                });
        });
        return p;
    };
};

export const update_workspace_settings = (workspace, payload) => {
    return (dispatch /*, getState*/) => {
        dispatch(updateSettings(workspace, payload));
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/settings/${encodeURIComponent(workspace)}`, payload)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const validate_cgu = () => (dispatch, getState) =>
    makeCancelable(
        axios.put(`/api/1.0/cgu`).then(({ data }) => {
            dispatch({
                type: VALIDATE_CGU,
            });
            return data;
        }),
    );
