/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * Internal Dependencies
 */
import Icon from '../../../components/icon';
import DataTables from '../../../components/data-tables';

import { makeCancelable } from '../../../store/utils';
import { get_custom_users, edit_custom_user, delete_custom_user } from '../../../actions';
import ModalConfirmation from '../../../components/modal/modalConfirmation';
import { normalize } from '../../../tools';

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filter: '',
            toggleConfirm: false,
            toggleMessage: '',
            delConfirm: false,
            delMessage: '',
            error: false,
        };
    }
    componentDidMount = () => {
        this.getUsers();
    };

    componentWillUnmount = () => {
        this.getusers && this.getusers.cancel();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.vthing.value !== prevProps.vthing.value) {
            this.getusers && this.getusers.cancel();
            this.setState({ loading: true, users: [] });
            this.getUsers();
        }
    };

    handleSearch = (e) => {
        const { value } = e.target;
        this.setState({ filter: value, loading: true }, () => this.setState({ loading: false }));
    };

    getUsers = () => {
        if (!this.props.app || !this.props.app.query) return;
        this.getusers = makeCancelable(this.props.get_custom_users(this.props.app.query.ws, this.props.vthing.value));
        this.getusers.promise
            .then((result) => {
                this.getusers = null;
                setImmediate(() => {
                    this.setState({ error: false, loading: false, users: result.list, description: result.meta });
                });
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.getusers = null;
                this.setState({ loading: false, error: true });
            });
    };

    handleEditConfirm = (user) => (e) => {
        e.preventDefault();
        if (user.disabled === true) {
            this.setState({ toggleConfirm: true, user, toggleMessage: `Are you sure to enable access for this user?` });
        } else {
            this.setState({ toggleConfirm: true, user, toggleMessage: `Are you sure to disable access for this user?` });
        }
    };

    editUser = (validate) => {
        const { user } = this.state;
        this.setState({ toggleConfirm: false, loading: validate, user: null, toggleMessage: '' });
        if (!validate) return;

        if (!this.props.app || !this.props.app.query) return;
        user.disabled = !(user.disabled === true);
        this.update = makeCancelable(this.props.edit_custom_user(this.props.app.query.ws, this.props.vthing.value, user));
        this.update.promise
            .then(() => {
                this.getUsers();
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.setState({ loading: false });
                this.getUsers();
            })
            .finally(() => {
                this.update = null;
            });
    };

    removeUser = (validate) => {
        const { delId } = this.state;
        this.setState({ delConfirm: false, loading: validate, delId: '', delMessage: '' });
        if (!validate) return;

        this.delUser = makeCancelable(this.props.delete_custom_user(this.props.app.query.ws, this.props.vthing.value, delId));
        this.delUser.promise
            .then(() => {
                this.getUsers();
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
                this.setState({ loading: false });
                this.getUsers();
            })
            .finally(() => {
                this.delUser = null;
            });
    };

    handleDelConfirm = (user) => (e) => {
        e.preventDefault();
        this.setState({ delConfirm: true, delId: user.id, delMessage: `Are you sure to delete?` });
    };

    /*=============================================
    =            Section render            =
    =============================================*/
    render_header = () => {
        return this.state.description.map((item, idx) => {
            return (
                <th key={idx} scope="col">
                    {item.label}
                    <Icon name="chevron-down" />
                </th>
            );
        });
    };

    render_users = () => {
        const { users, description, filter } = this.state;
        let tmp = users;
        if (filter.length > 0) {
            const filter_string = normalize(filter);
            tmp = users.filter((user) => {
                return description.find((item) => typeof user[item.name] == 'string' && normalize(user[item.name]).indexOf(filter_string) != -1);
            });
        }
        return tmp.map((user) => {
            user.cant = { disable: true, delete: true, ...user.cant };
            return (
                <tr key={user.id}>
                    {description.map((item, idx) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/scope
                            <td key={idx} scope="row" className="rui-filemanager-table-login">
                                <span className="rui-filemanager-file">{user[item.name]}</span>
                            </td>
                        );
                    })}
                    <td className="rui-filemanager-table-actions">
                        <span className="rui-filemanager-file">
                            {user.cant.disable ? (
                                <Link className="rui-filemanager-file-icon mr-10" to="#" onClick={this.handleEditConfirm(user)}>
                                    <Icon name={user.disabled === true ? 'play' : 'pause'} />
                                </Link>
                            ) : (
                                <span style={{ marginRight: '24px' }}>&nbsp;</span>
                            )}
                            {user.cant.delete && (
                                <Link className="rui-filemanager-file-icon mr-10" to="#" onClick={this.handleDelConfirm(user)}>
                                    <Icon name="x" />
                                </Link>
                            )}
                        </span>
                    </td>
                </tr>
            );
        });
    };

    render() {
        const { loading, error, filter, toggleConfirm, toggleMessage, delConfirm, delMessage } = this.state;
        return (
            <Fragment>
                {delConfirm && <ModalConfirmation isOpen={delConfirm} title="Confirm delete" content={delMessage} onResult={this.removeUser} />}
                {toggleConfirm && <ModalConfirmation isOpen={toggleConfirm} title="Change user status" content={toggleMessage} onResult={this.editUser} />}
                <div>
                    <div>
                        <div className="rui-filemanager">
                            <div className="rui-filemanager-head">
                                <div className="row sm-gap vertical-gap align-items-center">
                                    <div className="col">
                                        <div className="input-group">
                                            <div className="input-group-prepend mnl-3">
                                                <button type="button" className="btn btn-clean btn-grey-5 mb-0 mnl-10">
                                                    <Icon name="search" />
                                                </button>
                                            </div>
                                            <input
                                                type="search"
                                                value={filter}
                                                className="form-control form-control-clean rui-search-input"
                                                placeholder="Type to search..."
                                                onChange={this.handleSearch}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading && <Spinner color="secondary" type="grow"></Spinner>}
                            {!loading && !error && this.state.description && (
                                <Fragment>
                                    <div className="rui-filemanager-content">
                                        <div className="table-responsive-lg">
                                            <DataTables
                                                id="toto"
                                                className="rui-datatable rui-filemanager-table table mb-0"
                                                data={{
                                                    initComplete: () => {
                                                        window.jQuery('.dataTables_scrollBody:not(.rui-scrollbar)').addClass('rui-scrollbar');
                                                        window.jQuery(window).trigger('rui-ajax-loaded');
                                                    },
                                                    scrollY: 'calc(100vh - 475px)',
                                                    order: [0, 'asc'],
                                                    paging: false,
                                                    info: false,
                                                    searching: false,
                                                    columnDefs: [{ targets: -1, orderable: false }],
                                                }}
                                            >
                                                <thead>
                                                    <tr>
                                                        {this.render_header()}
                                                        <th className="rui-datatable-empty" />
                                                    </tr>
                                                </thead>
                                                <tbody>{this.render_users()}</tbody>
                                            </DataTables>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Content.propTypes = {
    vthing: PropTypes.any.isRequired,

    app: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    settings: PropTypes.any.isRequired,

    get_custom_users: PropTypes.func.isRequired,
    edit_custom_user: PropTypes.func.isRequired,
    delete_custom_user: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth, app }) => ({
        settings,
        auth,
        app,
    }),
    {
        get_custom_users,
        edit_custom_user,
        delete_custom_user,
    },
)(withRouter(Content));
