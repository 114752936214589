// ######################################################################
//                              IMPORT
// ######################################################################
import axios from 'axios';

// ######################################################################
//                              tokens
// ######################################################################
export const get_tokens = (company_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: { company_id },
        };
        const p = new Promise((resolve, reject) => {
            axios
                .get(`/api/1.0/token`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const create_token = (token) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .post(`/api/1.0/token`, token, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const update_token = (token) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/token/${encodeURIComponent(token.id)}`, token, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const reset_token = (token_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .put(`/api/1.0/token/${encodeURIComponent(token_id)}/renew`, null, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};

export const delete_token = (token_id) => {
    return (dispatch /*, getState*/) => {
        const config = {
            params: {},
        };
        const p = new Promise((resolve, reject) => {
            axios
                .delete(`/api/1.0/token/${encodeURIComponent(token_id)}`, config)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return p;
    };
};