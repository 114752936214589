/**
 * External Dependencies
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';
import { convertDate } from '../../tools';
import withLocalStorage from '../../components/with-local-storage';
import { Badge } from 'reactstrap';
import { eTokenType } from './modalToken';
import * as Roles from '../../types/roles';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const TokensTable = ({ tokens, filter, onDelete, onEdit, rowPerPage, userlvl }) => {
    const columns = useMemo(
        () => [
            {
                name: 'Name',
                selector: (token) => token.name,
                sortable: true,
            },
            {
                name: 'TokenID',
                selector: (token) => token.id,
                sortable: true,
            },
            {
                name: 'Type',
                selector: (token) => (token.type == eTokenType.ODATA ? <Badge color="secondary">BI</Badge> : <Badge color="primary">API</Badge>),
                sortable: true,
            },
            {
                name: 'Role',
                selector: (token) => {
                    if (token.type == eTokenType.API) {
                        const role = Roles._roles[token.role] || Roles._default_role;
                        return role.label;
                    } else {
                        return '-';
                    }
                },
                sortable: true,
            },
            {
                name: 'Created at',
                selector: (token) => convertDate(token.created_at),
                sortable: true,
            },
            {
                name: '',
                width: '100px',
                right: true,
                selector: (token) => {
                    const role = Roles._roles[token.role] || Roles._default_role;
                    return (
                        <>
                            {(role.lvl < 2 || userlvl >= 2) && (
                                <button
                                    type="button"
                                    className="btn btn-custom-round btn-custom-round-tab mr-10"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onEdit(token);
                                    }}
                                >
                                    <Icon name="edit" />
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-custom-round btn-custom-round-tab"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    MySwal.fire({
                                        position: 'top',
                                        title: `Are you sure to delete "${token.name}"`,
                                        icon: 'warning',
                                        html: "You won't be able to revert this!",
                                        showCancelButton: true,
                                        reverseButtons: true,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            onDelete(token);
                                        }
                                    });
                                }}
                            >
                                <Icon name="x" />
                            </button>
                        </>
                    );
                },
            },
        ],
        [onDelete, onEdit, userlvl],
    );

    const datas = useMemo(() => {
        return filter.length ? tokens.filter((token) => token.name.indexOf(filter) != -1) : tokens;
    }, [tokens, filter]);

    return (
        <DataTable
            persistTableHead
            noDataComponent="No data available"
            columns={columns}
            pagination
            data={datas}
            paginationPerPage={rowPerPage.get()}
            onChangeRowsPerPage={(rpp) => {
                rowPerPage.set(rpp);
            }}
        />
    );
};

TokensTable.defaultProps = {
    filter: '',
};
TokensTable.propTypes = {
    filter: PropTypes.string.isRequired,
    tokens: PropTypes.arrayOf(PropTypes.object).isRequired,
    userlvl: PropTypes.number.isRequired,

    rowPerPage: PropTypes.any,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

export default withLocalStorage(TokensTable);
