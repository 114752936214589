/**
 * External Dependencies
 */
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { AgoraWorkspaceContext } from '.';

/**
 * Internal Dependencies
 */
// import { normalize } from '../../../tools';

/*=============================================
=            Control declaration              =
=============================================*/
const WorkspaceConfigurationFooter = ({ edit }) => {
    /*=============================================
    =            State declaration                =
    =============================================*/
    const { customAction, handleClose, canSave, onSave } = useContext(AgoraWorkspaceContext);

    /*=============================================
    =            Actions section                  =
    =============================================*/

    /*=============================================
    =            Render section                   =
    =============================================*/
    return useMemo(
        () => (
            <>
                {customAction}
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    type="submit"
                    color="brand"
                    disabled={!canSave}
                    onClick={(e) => {
                        e.preventDefault();
                        onSave.current?.(e);
                    }}
                >
                    {edit ? 'Save' : 'Create Workspace'}
                </Button>
            </>
        ),
        [canSave, customAction, edit, handleClose, onSave],
    );
};

/*=============================================
=            props declaration                =
=============================================*/
WorkspaceConfigurationFooter.defaultProps = {};

WorkspaceConfigurationFooter.propTypes = {
    edit: PropTypes.any.isRequired,
};

export default connect(null, null)(WorkspaceConfigurationFooter);
