import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const OpenAi = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 84 84" fill={color}
        stroke={color}
        strokeWidth="1"
        {...rest}>
        <path d="M36.751.001c-9.116 0-17.215 5.87-20.038 14.534A20.83 20.83 0 0 0 2.828 24.61C-1.744 32.512-.7 42.446 5.425 49.219 3.534 54.892 4.183 61.1 7.203 66.237c4.544 7.93 13.687 11.994 22.634 10.103a20.78 20.78 0 0 0 15.635 6.999c9.116 0 17.215-5.87 20.038-14.534 5.87-1.214 10.922-4.883 13.857-10.075 4.6-7.902 3.556-17.836-2.568-24.609v-.028a20.76 20.76 0 0 0-1.778-17.046C70.476 9.145 61.332 5.08 52.414 6.971A20.86 20.86 0 0 0 36.751.001zm0 5.419l-.028.028c3.669 0 7.197 1.27 10.019 3.613-.113.056-.339.197-.508.282L29.64 18.91c-.847.48-1.355 1.383-1.355 2.371v22.464l-7.14-4.12v-18.57A15.63 15.63 0 0 1 36.751 5.419zm19.99 6.54a15.62 15.62 0 0 1 13.566 7.825c1.806 3.161 2.483 6.858 1.862 10.442-.113-.085-.338-.197-.48-.282l-16.594-9.596a2.78 2.78 0 0 0-2.737 0L32.913 31.581V23.34l16.058-9.285a15.54 15.54 0 0 1 7.77-2.096zm-41.043 8.53v19.727c0 .988.508 1.863 1.355 2.371l19.416 11.204L29.3 57.94l-16.03-9.257a15.63 15.63 0 0 1-5.7-21.336 15.65 15.65 0 0 1 8.128-6.858zm37.196 4.882l16.058 9.257c7.479 4.318 10.018 13.857 5.7 21.336l.028.028c-1.834 3.161-4.713 5.588-8.128 6.83V43.095c0-.988-.508-1.891-1.355-2.37L45.753 29.492zm-11.797 6.802l8.185 4.741v9.454l-8.185 4.741-8.184-4.741v-9.454zm12.869 7.451l7.14 4.12v18.542c0 8.636-6.999 15.635-15.606 15.635v-.028c-3.641 0-7.197-1.27-9.991-3.612.113-.056.367-.198.508-.283l16.594-9.567c.847-.48 1.383-1.383 1.354-2.371zM49.309 51.76V60l-16.058 9.257c-7.479 4.29-17.018 1.75-21.336-5.701h.028c-1.834-3.133-2.484-6.858-1.863-10.442.113.085.339.197.48.282l16.594 9.596a2.78 2.78 0 0 0 2.737 0z" />
        </svg>
    );
});

OpenAi.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OpenAi.displayName = 'OpenAi';

export default OpenAi;
