/**
 * External Dependencies
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames/dedupe';
import PropTypes from 'prop-types';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';

import { makeCancelable } from '../../store/utils';
import { create_token, update_token, reset_token } from '../../actions';
import ModalConfirmation from '../../components/modal/modalConfirmation';
import CustomInputRadio from '../../components/custom-inputradio';
import InputClipBoard from '../../components/input-clipboard';

export const eTokenType = {
    API: 'api',
    ODATA: 'odata',
};

/**
 * Component
 */
class Content extends Component {
    constructor(props) {
        super(props);

        // name, role, disabled
        this.state = {
            name: '',
            role: 'companyUser',
            type: eTokenType.API,
            disabled: false,
            edit: false,
            responseError: '',
            secret: '',
            hasPartner: this.props.company.type == 'Partner'
        };
        this.roleOptions = [
            { value: 'companyUser', label: 'Organization Analyst' },
            { value: 'companyAdmin', label: 'Organization Administrator' },
        ];
        if (this.state.hasPartner) {
            if (this.props.auth.role === 'companyPartner') {
                this.roleOptions.push({ value: 'companyPartner', label: 'Partner Administrator' });
            }
            if (this.props.auth.role === 'superAdmin') {
                this.roleOptions.push({ value: 'companyPartner', label: 'Partner Administrator' });
                this.roleOptions.push({ value: 'companyDeploy', label: 'Organization Deployer' });
            }
        }

        if (this.props.token.id) {
            this.state = { ...this.state, ...this.props.token, edit: true };
            const r = this.roleOptions.find((item) => this.props.token.role == item.value);
            this.defaultRole = r || { value: 'companyUser', label: 'Organization Analyst' };
        } else {
            this.defaultRole = { value: 'companyUser', label: 'Organization Analyst' };
        }
    }

    componentWillUnmount = () => {
        this.addToken && this.addToken.cancel();
        this.updateToken && this.updateToken.cancel();
        this.resetToken && this.resetToken.cancel();
        this.errorReset && clearTimeout(this.errorReset);
    };

    handleReset = (ret) => {
        if (!ret) {
            return this.setState({ resetConfirm: false });
        }
        this.errorReset && clearTimeout(this.errorReset);
        this.setState({ loading: false, resetConfirm: false, resetError: '' });
        this.resetToken = makeCancelable(this.props.reset_token(this.state.id));
        this.resetToken.promise
            .then((t) => {
                this.resetToken = null;
                this.setState({ loading: false, secret: t.secret });
            })
            .catch((error) => {
                this.resetToken = null;
                if (error.isCanceled === true) return;
                this.setState({ loading: false, resetError: 'An error has occurred.' });
                this.errorReset = setTimeout(() => {
                    this.errorReset = null;
                    this.setState({ resetError: '' });
                });
            });
    };

    askResetPassword = (e) => {
        e.preventDefault();
        this.setState({ resetConfirm: true });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const token = (({ id, name, role, disabled, type }) => ({ id, name, role, disabled, type }))(this.state);

        if (token.id) {
            this.updateToken = makeCancelable(this.props.update_token(token));
            this.updateToken.promise
                .then((u) => {
                    this.updateToken = null;
                    this.props.onSubmit(token);
                })
                .catch((error) => {
                    this.updateToken = null;
                    if (error.isCanceled === true) return;
                    let responseError = 'unknown error';
                    if (error.response && error.response.status === 409) responseError = 'token already exists';
                    if (error.response && error.response.status === 400 && typeof error.response.data.type === 'string')
                        responseError = `invalid ${error.response.data.type}`;
                    this.setState({ loading: false, responseError });
                });
        } else {
            if (this.props.auth.role === 'superAdmin' || this.props.auth.role === 'companyPartner') {
                token.company_id = this.props.company.value;
            }
            this.addToken = makeCancelable(this.props.create_token(token));
            this.addToken.promise
                .then((t) => {
                    this.addToken = null;
                    this.setState({ secret: t.secret, edit: true, id: t.id });
                })
                .catch((error) => {
                    this.addToken = null;
                    if (error.isCanceled === true) return;
                    let responseError = 'unknown error';
                    if (error.response && error.response.status === 409) responseError = 'token already exists';
                    if (error.response && error.response.status === 400 && typeof error.response.data.type === 'string')
                        responseError = `invalid ${error.response.data.type}`;
                    this.setState({ loading: false, responseError });
                });
        }
    };
    onClose = () => {
        this.props.onClose(this.state.edit && !this.props.token.id);
    };
    setName = ({ target: { value } }) => {
        this.setState({ name: value, responseError: '' });
    };
    setRole = ({ value }) => {
        this.setState({ role: value, responseError: '' });
    };
    setDisabled = (e) => {
        this.setState({ disabled: e.target.checked });
    };

    render() {
        const { name, role, edit, disabled, resetConfirm, responseError, resetError } = this.state;
        return (
            <Fragment>
                {resetConfirm && (
                    <ModalConfirmation isOpen title="Confirm reset secret" content={`Are you sure to reset "${name}" secret?`} onResult={this.handleReset} />
                )}
                <Modal isOpen={true} toggle={this.onClose} fade className={`rui-snippet rui-snippet-frame ${this.props.className}`}>
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title h2">{edit ? 'Update token' : 'Create new token'}</h5>
                            <Button className="close" color="" onClick={this.onClose}>
                                <Icon name="x" />
                            </Button>
                        </div>

                        <ModalBody className="rui-snippet-preview pb-5 mb-15">
                            <FormGroup>
                                <Label>Choose token type</Label>
                                <div
                                    className="row"
                                    onChange={({ target: { value } }) => {
                                        this.setState({ type: value });
                                    }}
                                >
                                    <div className="col-6">
                                        <CustomInputRadio
                                            id={eTokenType.API}
                                            title="API"
                                            subTitle="REST API"
                                            icon_name="setting-api"
                                            icon_vendor="agora"
                                            checked={this.state.type === eTokenType.API}
                                            value={eTokenType.API}
                                            group="token_type"
                                        ></CustomInputRadio>
                                    </div>
                                    <div className="col-6">
                                        <CustomInputRadio
                                            id={eTokenType.ODATA}
                                            title="BI"
                                            subTitle="OData API"
                                            checked={this.state.type === eTokenType.ODATA}
                                            icon_name="setting-odata"
                                            icon_vendor="agora"
                                            value={eTokenType.ODATA}
                                            group="token_type"
                                        ></CustomInputRadio>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="imageName">Name</Label>
                                <Input type="text" disabled={edit} name="name" id="name" value={name} onChange={this.setName} />
                            </FormGroup>
                            {this.state.type === eTokenType.API ? (
                                <>
                                    {(this.state.role !== 'companyDeploy' || this.props.auth.role === 'superAdmin') && (
                                        <FormGroup>
                                            <Label for="imageName">Role</Label>
                                            <Select
                                                classNamePrefix="agora"
                                                name="role"
                                                id="role"
                                                isClearable={false}
                                                defaultValue={this.defaultRole}
                                                options={this.roleOptions}
                                                onChange={this.setRole}
                                                noOptionsMessage={() => 'No roles...'}
                                            />
                                        </FormGroup>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                            <FormGroup>
                                <CustomInput type="checkbox" id="disabled" checked={disabled} label="Disable token" onChange={this.setDisabled} />
                            </FormGroup>
                            {this.state.secret.length > 0 && (
                                <>
                                    <FormGroup>
                                        <Label for="token">TokenID/Login</Label>
                                        <InputClipBoard id="token" text={this.state.id}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="secret">Secret/password</Label>
                                        <InputClipBoard id="secret" text={this.state.secret}/>
                                    </FormGroup>
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            {edit && (
                                <Fragment>
                                    <Button
                                        color="warning"
                                        className={classnames('btn-user-reset', { 'is-invalid': resetError })}
                                        onClick={this.askResetPassword}
                                    >
                                        Reset secret
                                    </Button>{' '}
                                </Fragment>
                            )}
                            <Button color="secondary" onClick={this.onClose}>
                                Close
                            </Button>{' '}
                            <Button
                                className={classnames('', { 'is-invalid': responseError })}
                                type="submit"
                                color="brand"
                                disabled={name.length < 4 || !role.length}
                                onClick={this.onSubmit}
                            >
                                {edit ? 'Update' : 'Create'}
                            </Button>
                            {responseError ? <div className="invalid-feedback">{responseError}</div> : ''}
                            {resetError ? <div className="invalid-feedback">{resetError}</div> : ''}
                        </ModalFooter>
                    </form>
                </Modal>
            </Fragment>
        );
    }
}

Content.defaultProps = {
    className: '',
};

Content.propTypes = {
    className: PropTypes.string,
    settings: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    company: PropTypes.object.isRequired,
    token: PropTypes.any.isRequired,

    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    create_token: PropTypes.func.isRequired,
    reset_token: PropTypes.func.isRequired,
    update_token: PropTypes.func.isRequired,
};

export default connect(
    ({ settings, auth }) => ({
        settings,
        auth,
    }),
    { create_token, update_token, reset_token },
)(Content);
