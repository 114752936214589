/**
 * External Dependencies
 */
import { lazy } from '@loadable/component';
import React, { Suspense } from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const Loading = () => (
    <Spinner color="secondary" type="grow">
        Loading...
    </Spinner>
);
export default function AsyncComponent(props) {
    const LoadableComponent = lazy(props.component);
    return (
        <Suspense fallback={<Loading />}>
            <LoadableComponent {...props.options} />
        </Suspense>
    );
}

AsyncComponent.propTypes = {
    component: PropTypes.any,
    options: PropTypes.any,
};
