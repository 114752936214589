/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/dedupe';

import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
/**
 * Component
 */
class PageWrap extends Component {
    render() {
        const { className, children, app } = this.props;

        if (!app.initialized)
            return (
                <div className="row text-center" style={{ height: '100vh' }}>
                    <div className="col-sm-12 my-auto">
                        <Spinner color="brand" type="grow">
                            Loading...
                        </Spinner>
                    </div>
                </div>
            );

        return (
            <div className={classnames('rui-page rui-page-react content-wrap', className)}>
                <div>{children}</div>
            </div>
        );
    }
}

PageWrap.defaultProps = {
    className: '',
};

PageWrap.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    app: PropTypes.any.isRequired,
};

export default connect(
    ({ app }) => ({
        app,
    }),
    null,
)(PageWrap);
