/**
 * Styles.
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Tabs from '../../components/tabs';

import { get_charts } from '../../actions/logs';
import { makeCancelable } from '../../store/utils';
import Icon from '../../components/icon';
import ChartModal from './ChartModal';
import TabNavItem from '../../components/tab-navItem-tips';

const ButtonAddTab = ({ chartAdded }) => {
    const [chartModal, setChartModal] = useState(false);

    const itemCreateDone = (chart) => {
        setChartModal(false);
        chartAdded(chart.id);
    };
    const itemCreateCancel = () => {
        setChartModal(false);
    };

    return (
        <>
            {chartModal && <ChartModal onSubmit={itemCreateDone} onClose={itemCreateCancel}></ChartModal>}

            <button type="button" className="ml-15 btn btn-grey-5 btn-custom-round mb-7" onClick={() => setChartModal(true)}>
                <Icon
                    style={{
                        width: 22,
                        height: 22,
                    }}
                    className="pt-5"
                    name="plus"
                />
            </button>
        </>
    );
};

ButtonAddTab.propTypes = {
    chartAdded: PropTypes.func.isRequired,
};

/**
 * Component
 */
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            charts: [],
            activeTab: 'home',
        };
    }
    toggleTab = (name) => {
        this.setState({
            activeTab: name,
        });
    };
    componentDidUpdate(prevProps) {
        if (this.props.app.query.ws != prevProps.app.query.ws || (!this.getcharts && this.props.app.query.ws)) {
            this.loadCharts();
            return;
        }
    }
    componentDidMount() {
        this.loadCharts();
    }
    componentWillUnmount() {
        this.getcharts && this.getcharts.cancel();
    }
    loadCharts = (cb) => {
        if (this.props.app.query.ws) {
            this.setState({ loading: true, activeTab: 'home' });
            this.getcharts = makeCancelable(this.props.get_charts(this.props.app.query.ws));
            this.getcharts.promise
                .then((result) => {
                    this.setState({ loading: false, charts: result.data });
                    cb?.();
                })
                .catch((error) => {
                    if (error.isCanceled === true) return;
                    this.setState({ loading: false, charts: [] });
                });
        }
    };

    handleChartSettings = (chart) => {
        this.setState({ charts: this.state.charts.map((c) => (c.id == chart.id ? { ...c, name: chart.name } : c)) }, () =>
            window.jQuery(window).trigger('rui-ajax-update-tab'),
        );
    };
    handleDeleteTab = (chartID) => {
        this.setState({ charts: this.state.charts.filter((c) => c.id !== chartID), activeTab: 'home' }, () =>
            window.jQuery(window).trigger('rui-ajax-update-tab'),
        );
    };
    handleNewTab = (chartID) => {
        this.loadCharts(() => {
            this.toggleTab(chartID);
            window.jQuery(window).trigger('rui-ajax-update-tab');
        });
    };

    render() {
        const { activeTab, loading, charts } = this.state;
        return (
            <>
                <PageWrap>
                    <PageTitle>
                        <h1>Dashboard</h1>
                    </PageTitle>
                    <PageContent>
                        {loading ? (
                            <Spinner color="secondary" type="grow"></Spinner>
                        ) : (
                            <div className="col-12">
                                <Tabs className="agora-dahboard" sliding>
                                    <Tabs.NavItem isActive={activeTab === 'home'} onClick={() => this.toggleTab('home')}>
                                        Home
                                    </Tabs.NavItem>
                                    {charts.map((chart) => (
                                        <TabNavItem tips={chart.name} id={chart.id} key={chart.id} isActive={activeTab === chart.id} onClick={() => this.toggleTab(chart.id)}>
                                            <span>{chart.name}</span>
                                        </TabNavItem>
                                    ))}

                                    {charts.length < 8 && <ButtonAddTab chartAdded={this.handleNewTab} />}
                                </Tabs>
                                <Tabs.Content activeTab={activeTab}>
                                    <Tabs.Pane tabId="home">{activeTab === 'home' && <AsyncComponent component={() => import('./content')} />}</Tabs.Pane>
                                    {charts.map((chart) => (
                                        <Tabs.Pane key={chart.id} tabId={chart.id}>
                                            {activeTab === chart.id && (
                                                <AsyncComponent
                                                    options={{ ...chart, onUpdateSettings: this.handleChartSettings, onDeleteTab: this.handleDeleteTab }}
                                                    component={() => import('./custom')}
                                                />
                                            )}
                                        </Tabs.Pane>
                                    ))}
                                </Tabs.Content>
                            </div>
                        )}
                    </PageContent>
                </PageWrap>
            </>
        );
    }
}

Dashboard.propTypes = {
    workspaces: PropTypes.array.isRequired,
    settings: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
    get_charts: PropTypes.func.isRequired,
};

export default connect(
    ({ workspaces, settings, app, auth }) => ({
        workspaces,
        settings,
        app,
        auth,
    }),
    { get_charts },
)(Dashboard);
