import { $, $wnd, $doc } from './_utility';
/*------------------------------------------------------------------

  Init Tabs Sliding

-------------------------------------------------------------------*/

function initTabsSliding() {
  function recalculateSlideSize(slide, item) {
    slide.css({
      width: item.innerWidth(),
      height: item.outerHeight(),
      transform: "translate(".concat(item.position().left, "px, ").concat(item.position().top, "px)")
    });
  }

  function updateTab() {
    $('.rui-tabs-sliding').each(function () {
      var $this = $(this);
      var $tabsObject = $this.closest('.rui-tabs-sliding').find('.rui-tabs-slide');
      var $tabsLinkActive = $this.find('.rui-tabs-link.active');

      if ($tabsLinkActive.length) {
        recalculateSlideSize($tabsObject, $tabsLinkActive);
      }
    });
  }

  function prepareSlidingTabs() {
    $('.rui-tabs-sliding:not(.rui-tabs-sliding-ready)').addClass('rui-tabs-sliding-ready').each(function () {
      var $this = $(this);
      var $tabsObject = $('<li class="rui-tabs-slide"></li>');
      var $tabsLinkActive = $this.find('.rui-tabs-link.active');
      $this.prepend($tabsObject);

      if ($tabsLinkActive.length) {
        recalculateSlideSize($tabsObject, $tabsLinkActive);
      }
    });
  }

  prepareSlidingTabs();
  $wnd.on('rui-ajax-loaded', prepareSlidingTabs);
  $wnd.on('rui-ajax-update-tab', updateTab);
  $doc.on('click', '.rui-tabs-link', function (e) {
    e.preventDefault();
    var $this = $(this);
    var $tabsObject = $this.closest('.rui-tabs-sliding').find('.rui-tabs-slide');

    if ($this.length) {
      recalculateSlideSize($tabsObject, $this);
    }
  });
}

export { initTabsSliding };