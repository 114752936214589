/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';

const ButtonTips = ({id, tips, children, ...rest}) => {
    return (
        <>
            <Button id={id} {...rest}>{children}</Button>
            <UncontrolledPopover placement="bottom" target={id} popperClassName="agora-tips" trigger="hover">
                <PopoverBody className="py-5">{tips}</PopoverBody>
            </UncontrolledPopover>
        </>
    );
};

ButtonTips.propTypes = {
    id: PropTypes.string.isRequired,
    tips: PropTypes.string.isRequired,
    children: PropTypes.object,
};

export default ButtonTips;