/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';

/**
 * Internal Dependencies
 */
import Icon from '../icon';

/**
 * Component
 */
class ModalConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: this.props.isOpen,
        };
    }

    toggle = (ret = false) => (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            modalOpen: !prevState.modalOpen,
        }));
        this.props.onResult(ret);
    };

    render() {
        const { t, title, content } = this.props;
        return (
            <div>
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle()} className={this.props.className} fade>
                    <div className="modal-header">
                        <h5 className="modal-title h2">{title}</h5>
                        <Button className="close" color="" onClick={this.toggle()}>
                            <Icon name="x" />
                        </Button>
                    </div>
                    <ModalBody>
                        <FormGroup>
                            <Label>{content}</Label>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle()}>
                            {t("Close")}
                        </Button>{' '}
                        <Button color="brand" onClick={this.toggle(true)}>
                            {t("Ok")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ModalConfirmation.defaultProps = {
    className: '',
    isOpen: true,
};

ModalConfirmation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    content: PropTypes.string.isRequired,

    onResult: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withTranslation("common")(ModalConfirmation);
