import React, { Component } from 'react';

const DEFAULT_DOMAINS = '{"domains": []}';
const DEFAULT_STORE = '{}';
// Cette fonction accepte un composant...
const withLocalStorage = (WrappedComponent) => {
    class LocalStorageController extends Component {
        static propTypes = {};

        constructor(props) {
            super(props);
        }

        componentDidUpdate = (prevProps) => {};
        getRowPerPage = () => {
            const val = parseInt(localStorage.getItem('agora-rpp'), 10);
            if (isNaN(val) || !val || val < 10) return 10;
            return val;
        };
        setRowPerPage = (val) => {
            if (val > 0) {
                localStorage.setItem('agora-rpp', val);
            } else {
                localStorage.setItem('agora-rpp', 10);
            }
        };

        getLeftPane = () => {
            const val = parseInt(localStorage.getItem('agora-leftpane'), 10);
            if (isNaN(val) || !val || val < 100) return 400;
            return val;
        };
        setLeftPane = (val) => {
            if (val > 100) {
                localStorage.setItem('agora-leftpane', val);
            }
        };

        getDemoStore = (workspace) => {
            const store = JSON.parse(localStorage.getItem('demo_store') || DEFAULT_STORE) || {};
            return { wl: store[workspace]?.wl?.reduce((a, v) => ({ ...a, ['vthing_' + v]: 1 }), {}), bl: store[workspace]?.bl?.reduce((a, v) => ({ ...a, ['vthing_' + v]: 1 }), {}) };
        };

        getSSODomains = () => {
            const multi_account_store = JSON.parse(localStorage.getItem('MultiAccountStore') || DEFAULT_DOMAINS) || {};
            multi_account_store.domains ??= [];
            return multi_account_store.domains.sort();
        };

        setSSODomains = (domain) => {
            const multi_account_store = JSON.parse(localStorage.getItem('MultiAccountStore') || DEFAULT_DOMAINS) || {};
            multi_account_store.domains ??= [];
            if (multi_account_store.domains.find((current) => domain == current)) return;
            multi_account_store.domains = multi_account_store.domains.slice(-3);
            multi_account_store.domains.push(domain);
            localStorage.setItem('MultiAccountStore', JSON.stringify({ domains: multi_account_store.domains }));
        };

        delSSODomains = (domain) => {
            const multi_account_store = JSON.parse(localStorage.getItem('MultiAccountStore') || DEFAULT_DOMAINS) || {};
            multi_account_store.domains ??= [];
            multi_account_store.domains = multi_account_store.domains.filter((current) => domain !== current);
            localStorage.setItem('MultiAccountStore', JSON.stringify({ domains: multi_account_store.domains }));
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    rowPerPage={{ get: this.getRowPerPage, set: this.setRowPerPage }}
                    leftPane={{ get: this.getLeftPane, set: this.setLeftPane }}
                    ssoDomains={{ get: this.getSSODomains, set: this.setSSODomains, del: this.delSSODomains }}
                    store={{ get: this.getDemoStore }}
                />
            );
        }
    }
    return LocalStorageController;
};

export default withLocalStorage;
