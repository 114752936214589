import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Event = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <path d="m 2.1781414,6.9536757 h 4.1037447 c 0,-4.8291216 3.8583379,-4.946703 6.1360719,-4.946703 l 3e-6,4.946703 h 9.489726 V 19.844382 h -9.479223 v -5.472399 c -1.786586,0 -6.1279176,1.164177 -6.1279176,5.484479 H 2.2097086 Z" />
        </svg>
    );
});

Event.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Event.displayName = 'Event';

export default Event;
