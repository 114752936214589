import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { get_odata_logs } from '../../actions/logs';
import { connect } from 'react-redux';
import Icon from '../icon';
import { convertDateTime } from '../../tools';

const MySwal = withReactContent(Swal);
const $ = window.jQuery;

const ODataLogs = ({ workspace, get_odata_logs, className }) => {
    const getlogsCancelable = useRef();

    useEffect(() => {
        return () => {
            getlogsCancelable.current?.cancel();
        };
    }, []);

    const onDisplay = useCallback((logs) => {
        MySwal.fire({
            position: 'top',
            title: `OData request logs`,
            html: `
<div class="pb-5">
<div id="agpra_odata-logs" class="list-group" style="text-align: left; max-height: 300px;overflow-y: auto;position: relative;">
    <div class="agora-div-table" style="min-width:800px">
        <div class="agora-div-body"></div>
    </div>
</div>
</div>`,
            focusConfirm: false,
            allowEnterKey: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Close',
        });
        /*=============================================
        =            Init options list                =
        ==============================================*/
        const parent = $('#agpra_odata-logs>.agora-div-table>.agora-div-body');
        parent.append(
            logs
                .map((e, idx) => {
                    return `<div class="list-group-item list-group-item-action agora-div-row ${idx == 0 ? 'selected' : ''}" data-agora="${idx}">
<div class="agora-div-cell" style="width:140px">${convertDateTime(e.timestamp)}</div>
<div class="agora-div-cell" style="width:40px">${e.status}</div>
<div class="agora-div-cell" style="width:50px">${e.method}</div>
<div class="agora-div-cell">${e.path}</div>
</div>`;
                })
                .join('\n'),
        );
    }, []);

    const onClick = useCallback(
        (e) => {
            getlogsCancelable.current = get_odata_logs(workspace);
            getlogsCancelable.current.promise
                .then((logs) => {
                    onDisplay(logs);
                })
                .catch((error) => {
                    if (error.isCanceled === true) return;
                    onDisplay([]);
                })
                .finally(() => {
                    getlogsCancelable.current = undefined;
                });
        },
        [get_odata_logs, onDisplay, workspace],
    );

    return (
        <button onClick={onClick} type="button" className={`btn btn-custom-round ${className}`}>
            <Icon name="info" />
        </button>
    );
};

ODataLogs.defaultProps = {
    className: '',
};
ODataLogs.propTypes = {
    className: PropTypes.string.isRequired,
    workspace: PropTypes.string.isRequired,
    get_odata_logs: PropTypes.func.isRequired,
};

export default connect(null, { get_odata_logs })(ODataLogs);
