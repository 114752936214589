

export const keymap_app = {
    AGORA_KEYS: {
        SAVE: {
            osx: ['command+s'], // todo @y-tor update shortcut for mac user
            windows: ['ctrl+s'],
            other: ['ctrl+s'],
        },
    },
};



class ShortCutsObserver {
    // observers = [];
    observers_save = [];
    attach = (observer, filter) => {
        switch (filter) {
            case 'save':
                this.observers_save.push(observer);
                break;
            default:
                // this.observers.push(observer);
                break;
        }
    };
    detach = (observerToRemove, filter) => {
        switch (filter) {
            case 'save':
                this.observers_save = this.observers_save.filter((observer) => observerToRemove !== observer);
                break;
            default:
                // this.observers = this.observers.filter((observer) => observerToRemove !== observer);
                break;
        }
    };
    notify = (action, event) => {
        switch (action) {
            case 'SAVE':
                event.preventDefault();
                this.observers_save.forEach((observer) => observer());
                return false;
            default:
                break;
        }
    };
}

const shortcutsObserver = new ShortCutsObserver();
export default shortcutsObserver;
