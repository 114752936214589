import './agora-radio.scss';
/**
 * External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';

/**
 * Component
 */
const CustomInputRadio = ({ id, title, subTitle, group, value, icon_name, icon_vendor, checked }) => {
    return (
        <>
            <input type="radio" className="agora-btn-check" checked={checked} name={group} value={value} id={id} onChange={() => {}} />
            <label className="d-flex btn btn-outline btn-brand px-10 px-25 mb-10 align-items-center" htmlFor={id}>
                {icon_name && <Icon style={{ width: 30, height: 30 }} name={icon_name} vendor={icon_vendor}></Icon>}
                <span className="d-block text-start">
                    {title != '' && <span className="d-block fs-16 mb-2">{title}</span>}
                    {subTitle && <span className="text-muted fs-12">{subTitle}</span>}
                </span>
            </label>
        </>
    );
};

CustomInputRadio.defaultProps = {
    icon_vendor: 'feather',
    checked: false,
};
CustomInputRadio.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    group: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    icon_name: PropTypes.any,
    icon_vendor: PropTypes.string.isRequired,
};

export default CustomInputRadio;
