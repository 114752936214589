export const SCRIPT_ACTIONS = {
    CREATE: 'CREATE',
    RENAME: 'RENAME',
    EDIT: 'EDIT',
    TOGGLE: 'TOGGLE',
    CLONE: 'CLONE',
    REVERT: 'REVERT',
    DELETE: 'DELETE',
    STATUS: 'STATUS',
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    INIT: 'INIT',
    PLAY: 'PLAY',
};
