/**
 * Styles
 */

/**
 * External Dependencies
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';

/**
 * Internal Dependencies
 */
import Icon from '../../icon';
import { get_configurationWorkspace, get_workspaceStats } from '../../../actions';
import ConfigurationBody from './content';
import ConfigurationFooter from './footer';
import SettingsConfig from './settings-config';

export const eSection = {
    DEFAULT: 'settings',
    SETTINGS: 'settings',
    GITHUB: 'github',
    SMTP: 'prompts',
};
export const components = {
    [eSection.SETTINGS]: { component: () => import('./settings-config'), label: 'General' },
    [eSection.GITHUB]: { component: () => import('./github-config'), label: 'GitHub' },
    [eSection.SMTP]: { component: () => import('./smtp-config'), label: 'SMTP' },
};

export const AgoraWorkspaceContext = React.createContext({ stats: null });

/**
 * Component
 */
const Content = ({ app, get_workspaceStats, get_configurationWorkspace, settingsMode: edit, onClose, className, workspaces, onSubmit }) => {
    /*=============================================
    =            State declaration                =
    =============================================*/
    const [loading, setLoading] = useState(true);
    const [github, setGithub] = useState(null);
    const [stats, setStats] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [SMTP, setSMTP] = useState(null);
    const [customAction, setCustomAction] = useState(<></>);
    const [companyId, setCompanyId] = useState('');
    const [currentWS, setCurrentWS] = useState(null);

    const onSave = useRef(() => {});
    const setOnSave = useCallback((callback) => {
        onSave.current = callback;
    }, []);

    useEffect(() => {
        let current = null;
        if (app.query.ws) {
            for (let i = 0; i != workspaces.length; i++) {
                if (app.query.ws == workspaces[i].workspace) {
                    const { company_id, label, language, workspace } = workspaces[i];
                    current = { company_id, label, language, workspace };
                }
            }
        }
        if (current) {
            if (edit) {
                setCurrentWS(current);
            }
            setCompanyId(current.company_id);
        }
        return null;
    }, [app.query.ws, edit, workspaces]);
    /*=============================================
    =            Actions section                  =
    =============================================*/
    const handleClose = useCallback(
        (e) => {
            onSubmit(e.workspace && !currentWS ? e : currentWS);
        },
        [currentWS, onSubmit],
    );

    useEffect(() => {
        if (edit) {
            const cancelable1 = get_configurationWorkspace(app.query.ws, 'github');
            cancelable1.promise = cancelable1.promise
                .then((result) => {
                    setGithub(result?.object);
                    return true;
                })
                .catch((error) => {
                    if (error.isCanceled === true) return false;
                    return true;
                });
            const cancelable2 = get_workspaceStats(app.query.ws);
            cancelable2.promise = cancelable2.promise
                .then((result) => {
                    setStats(result?.stats);
                    return true;
                })
                .catch((error) => {
                    if (error.isCanceled === true) return true;
                    return true;
                });
            const cancelable3 = get_configurationWorkspace(app.query.ws, 'smtp');
            cancelable3.promise = cancelable3.promise
                .then((result) => {
                    setSMTP(result?.object);
                    return true;
                })
                .catch((error) => {
                    if (error.isCanceled === true) return false;
                    return true;
                });
            Promise.all([cancelable1.promise, cancelable2.promise, cancelable3.promise]).then((results) => {
                if (results.every((r) => r === true)) {
                    setLoading(false);
                }
            });

            return () => {
                cancelable1?.cancel();
                cancelable2?.cancel();
                cancelable3?.cancel();
            };
        } else {
            // mode creation
            setLoading(false);
        }
    }, [app.query.ws, edit, get_configurationWorkspace, get_workspaceStats]);

    const control = useMemo(() => {
        return (
            <Modal isOpen={true} toggle={handleClose} fade className={`rui-snippet ${edit ? 'modal-lg' : ''} rui-snippet-frame ${className}`}>
                <form onSubmit={onSave.current}>
                    <div className="modal-header">
                        <h5 className="modal-title h2">{edit ? 'Workspace Settings' : 'Create new workspace'}</h5>
                        <Button className="close" color="" onClick={handleClose}>
                            <Icon name="x" />
                        </Button>
                    </div>

                    <ModalBody className="rui-snippet-preview pb-5 mb-15">
                        <>
                            {loading ? (
                                <Spinner color="secondary" type="grow"></Spinner>
                            ) : edit ? (
                                <ConfigurationBody></ConfigurationBody>
                            ) : (
                                <SettingsConfig></SettingsConfig>
                            )}
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <ConfigurationFooter edit={edit}></ConfigurationFooter>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }, [className, edit, loading, handleClose]);

    /*=============================================
    =            Render section                   =
    =============================================*/
    return useMemo(
        () => (
            <AgoraWorkspaceContext.Provider
                value={{
                    edit,
                    companyId,
                    currentWS,
                    setCurrentWS,
                    onSave,
                    setOnSave,
                    stats,
                    github,
                    setGithub,
                    SMTP,
                    setSMTP,
                    handleClose,
                    canSave,
                    setCanSave,
                    customAction,
                    setCustomAction,
                }}
            >
                {control}
            </AgoraWorkspaceContext.Provider>
        ),
        [SMTP, canSave, companyId, control, currentWS, customAction, edit, github, handleClose, setOnSave, stats],
    );
};

Content.propTypes = {
    app: PropTypes.any.isRequired,
    className: PropTypes.string,
    settingsMode: PropTypes.bool.isRequired,
    workspaces: PropTypes.any.isRequired,

    get_workspaceStats: PropTypes.func.isRequired,
    get_configurationWorkspace: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default connect(
    ({ app, workspaces }) => ({
        app,
        workspaces,
    }),
    { get_workspaceStats, get_configurationWorkspace },
)(Content);
