/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Collapse, Spinner } from 'reactstrap';
import './style.scss';

/**
 * Internal Dependencies
 */
import { makeCancelable } from '../../store/utils';
import { signOut, updateInfo, post_workspace, delete_workspace, get_workspaces } from '../../actions';
import { flush_cache } from '../../actions/admin';
import Dropdown from '../bs-dropdown';
import Icon from '../icon';
import ChangePassword from '../change-password';
//import Messenger from '../messenger';
import { initNavbar } from '../../../../common-assets/js/rootui-parts/initNavbar';
import querystring from 'querystring';
import ButtonHover from '../button-tips';

//import ModalWorkspace from './modalWorkspace';
import ModalWorkspace from './WorkspaceConfiguration';
import ModalConfirmation from '../modal/modalConfirmation';
import { normalize } from '../../tools';

const $ = window.jQuery;
const _default_role = { lvl: 0, label: 'Organization Analyst' };
const _roles = {
    companyPartner: { lvl: 3, label: 'Partner Administrator' },
    superAdmin: { lvl: 2, label: 'Super Administrator' },
    companyAdmin: { lvl: 1, label: 'Organization Administrator' },
    companyUser: _default_role,
};

window.RootUI.initNavbar = initNavbar;

/**
 * Component
 */
class PageNavbar extends Component {
    constructor(props) {
        super(props);

        window.RootUI.initNavbar();

        this.state = {
            filter: "",
            modalOpen: false,
            mobileMenuShow: false,
            confirmation: null,
            confirmationContent: null,
            confirmationContext: null,
            updatePassword: false,
            settingsMode: false,
            loading: false,
        };

        if (this.props.auth.avatar && this.props.auth.avatar.data) {
            const b = Buffer.from(this.props.auth.avatar.data.data).toString('base64');
            this.state.avatar = `data:image/png;base64,${b}`;
        } else {
            this.state.avatar = this.props.settings.users[0].img;
        }
        const v = _roles[this.props.auth.role] || _default_role;
        this.state.role = v.label;
        this.logOut = this.logOut.bind(this);
        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.renderRightMenuItems = this.renderRightMenuItems.bind(this);
    }

    componentDidMount() {
        $(document).on('keydown.rui-navbar', (e) => {
            const { mobileMenuShow } = this.state;

            if (mobileMenuShow && e.keyCode === 27) {
                this.setState({
                    mobileMenuShow: !mobileMenuShow,
                });
            }
        });
    }

    componentWillUnmount() {
        $(document).off('keydown.rui-navbar');
        if (this.deleteWorkspace) this.deleteWorkspace.cancel();
        if (this.getWorkspaces) this.getWorkspaces.cancel();
    }

    changePassword = (e) => {
        e.preventDefault();
        this.setState({ updatePassword: true });
    };

    logOut() {
        const { signOut } = this.props;
        signOut();
    }

    handleAddWorkspace = (e) => {
        e.preventDefault();
        this.setState({ modalOpen: true, settingsMode: false });
    };
    handleSettingsWorkspace = (e) => {
        e.preventDefault();
        this.setState({ modalOpen: true, settingsMode: true });
    };

    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    flushCache = async (e) => {
        e.preventDefault();
        await makeCancelable(this.props.flush_cache());
    }

    handleSubmitWorkspace = (workspace) => {
        this.handleClose();
        if (!(workspace?.workspace)) return; // create canceled

        let current = null;
        for (let i = 0; i != this.props.workspaces.length; i++) {
            if (this.props.app.query.ws == this.props.workspaces[i].workspace) {
                current = this.props.workspaces[i];
                break;
            }
        }
        if (!current) return;
        this.setState({ loading: true });
        if (current.workspace != workspace.workspace || current.label != workspace.label || current.language != workspace.language ) {
                this.getWorkspaces = makeCancelable(this.props.get_workspaces());
                this.getWorkspaces.promise
                .then(() => {
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    if (error.isCanceled === true) return;
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ loading: false });
        }
    }

    handleDeleteWorkspace = (workspaceName) => {
        if (workspaceName != 'default') {
            this.deleteWorkspace = makeCancelable(this.props.delete_workspace(workspaceName));
            this.deleteWorkspace.promise
                .then((u) => {
                    this.getWorkspaces = makeCancelable(this.props.get_workspaces());
                    this.getWorkspaces.promise
                        .then((u) => {})
                        .catch((error) => {
                            if (error.isCanceled === true) return;
                        });
                })
                .catch((error) => {
                    console.log('delete workspace error ', error);
                    if (error.isCanceled === true) return;
                    this.setState({ loading: false });
                });
        }
    };

    renderSubmenus(nav, isMobile, level = 1) {
        const { workspaces, location } = this.props;
        const queries = querystring.parse(location.search.replace(/^\?/g, ''));
        let label = queries.ws;
        if (queries.ws) {
            for (let i = 0; i != workspaces.length; i++) {
                if (queries.ws == workspaces[i].workspace) {
                    label = workspaces[i].label;
                    break;
                }
            }
        }
        const data = nav['#actions'];
        const LinkContent = data.name ? (
            <>
                {data.icon && label ? (
                    <>
                        <Icon name={data.icon} />
                        <span style={{ marginRight: 'auto' }}>
                            {data.name + '  '}
                            <strong style={{ textDecorationLine: 'underline', textDecorationStyle: 'solid' }}>{ label.replace(/^(.*)_/, '$1\\')}</strong>
                        </span>
                        <span style={isMobile ? {} : {top:-2}} className={data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle'} />
                    </>
                ) : (
                    data.name
                )}
            </>
        ) : (
            ''
        );

        let filtered = workspaces;
        const displayFilter = workspaces.length > 10;
        if (displayFilter && this.state.filter.length) {
            const normalSearch = normalize(this.state.filter);
            normalSearch ? filtered = workspaces.filter((element) => {
                const normalName = normalize(element.workspace);
                const label = normalize(element.label);
                return normalName.indexOf(normalSearch) >= 0 || label.indexOf(normalSearch) >= 0;
            } ) : workspaces;
        }
        const table = filtered.map((element) => {
            const query = querystring.stringify({ ...queries, ws: element.workspace });
            const newpath = `${location.pathname}?${query}`;
            return (
                <li key={element.workspace} className={classnames('nav-item', 'active')}>
                    <Link to={`${newpath}`} className="nav-link">
                        <span>{element.label.replace(/^(.*)_/, '$1\\')}</span>
                        {queries.ws === element.workspace ? (
                            <Icon name="check" />
                        ) : (
                            element.workspace != 'default' &&
                            element.workspace != 'default_default' &&
                            !element.workspace.endsWith('_default') &&
                            (this.props.auth.role == 'superAdmin' || this.props.auth.role == 'companyAdmin' || this.props.auth.role == 'companyPartner') && (
                                <Icon
                                    name="x"
                                    onClick={(e) => {
                                        this.setState({
                                            confirmation: 'Confirmation',
                                            confirmationContent: 'Are you sure to delete this workspace?',
                                            confirmationContext: element.workspace,
                                        });
                                        e.preventDefault();
                                    }}
                                />
                            )
                        )}
                    </Link>
                </li>
            );
        });

        return (
            <React.Fragment key={`${'#actions'}-${data.name}`}>
                {this.state.confirmation != null && (
                    <ModalConfirmation
                        isOpen={this.state.confirmation != null}
                        title={this.state.confirmation}
                        content={this.state.confirmationContent}
                        onResult={(ret) => {
                            if (ret == true) this.handleDeleteWorkspace(this.state.confirmationContext);
                            this.setState({ confirmation: null });
                        }}
                    />
                )}
                <div className="row" style={{marginLeft:-5}}>
                    <Dropdown tag="li" className={classnames('active col-10')} direction={level === 1 ? 'up' : 'right'} openOnHover={!isMobile} showTriangle onToggle={(open)=> {
                                open && window.jQuery(window).trigger('rui-ajax-loaded');

                    }}>
                        <Dropdown.Toggle tag="a" href="#" className="dropdown-item" onClick={(e) => e.preventDefault()}>
                        {LinkContent}
                        </Dropdown.Toggle>
                        <Dropdown.Menu tag="ul" className="nav dropdown-menu nav-workspace">
                            <div >
                                {displayFilter && (
                                    <div className="col input-group">
                                        <input
                                            type="search"
                                            value={this.state.filter}
                                            className="form-control form-control-clean"
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            onChange={(e) => this.setState({filter: e.target.value })}
                                        />
                                        <button type="button" className="btn btn-clean btn-uniform btn-grey-5" data-toggle="button">
                                            <Icon name="search" />
                                        </button>
                                    </div>
                                )}
                                <div className="rui-scrollbar" style={{ maxHeight: 430 }}>
                                    <div>{table}</div>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    {this.props.auth.role == 'superAdmin' || this.props.auth.role == 'companyAdmin' || this.props.auth.role == 'companyPartner' ? (
                    <div className="row" style={{position: 'relative', left: 90}}>
                    <div className="row">
                        {this.state.loading == false ? <ButtonHover
                            type="button"
                            id="settings"
                            tips="Settings"
                            color="light"
                            className="mr-20 btn btn-sm btn-custom-round"
                            onClick={this.handleSettingsWorkspace}
                            >
                            <Icon style={{ width: 20, height: 20, top: 6 }} name="settings" />
                        </ButtonHover> : <Spinner color='secondary' style={{position: 'relative', height:20, width:20, top:5, marginRight:20}}/>}
                    </div>
                    <div className="row" style={{position: 'relative', left: 33}}>
                        <button type="button" className="btn btn-brand btn-sm btn-uniform btn-round" onClick={this.handleAddWorkspace}>
                        <Icon name="plus" />
                        </button>
                    </div>
                    </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
        /*
        return Object.keys( nav ).map( ( url ) => {
            const data = nav[ url ];

            const isActive = window.location.hash === `#${ url }`;

            const LinkContent = data.name ? (
                <>
                    { data.icon ? (
                        <>
                            <Icon name={ data.icon } />
                            <span>{ data.name }</span>
                            <span className={ data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle' } />
                        </>
                    ) : (
                        data.name
                    ) }
                </>
            ) : '';

            return (
                <React.Fragment key={ `${ url }-${ data.name }` }>
                    { data.sub ? (
                        <Dropdown tag="li" className={ classnames( isActive ? 'active' : '' ) } direction={ level === 1 ? 'up' : 'right' } openOnHover={ ! isMobile } showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                                { LinkContent }
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                { this.renderSubmenus( data.sub, isMobile, level + 1 ) }
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <li className={ classnames( 'nav-item', isActive ? 'active' : '' ) }>
                            <Link
                                to={ data.sub ? '#' : url }
                                className="nav-link"
                            >
                                { LinkContent }
                            </Link>
                        </li>
                    ) }
                </React.Fragment>
            );
        } );*/
    }

    renderRightMenuItems(isMobile) {
        const { settings, updateInfo } = this.props;
        return (
            <>
                {!isMobile ? (
                    <Dropdown tag="li" direction="up" openOnHover showTriangle>
                        <Dropdown.Toggle
                            tag="a"
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            className="dropdown-item rui-navbar-avatar mnr-6"
                        >
                            <img src={this.state.avatar} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu tag="ul" className="nav dropdown-menu dropdown-menu-agora">
                            <li className="nav-profile">
                                <img src={this.state.avatar} alt="" />
                                <div className="nav-item">
                                    <h3 className="nav-profile-title">{this.props.auth.login}</h3>
                                </div>
                                <small className="text-grey-6 mt-2 mb-15">{this.state.role}</small>
                            </li>
                            {this.props.auth.idp !== true && (
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" onClick={this.changePassword}>
                                        <Icon name="shield" />
                                        <span>Change password</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                            )}
                            {this.props.auth.role == "superAdmin" &&
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" onClick={this.flushCache}>
                                        <Icon name="trash" />
                                        <span>Flush cache</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                            }
                            <li className="nav-item">
                                <Link to="#" className="nav-link" onClick={this.logOut}>
                                    <Icon name="log-out" />
                                    <span>Log Out</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    ''
                )}
                <Dropdown tag="li" direction="up" openOnHover={!isMobile} showTriangle>
                    <Dropdown.Toggle
                        tag="a"
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className={classnames('dropdown-item mnr-5', isMobile ? 'nav-link' : '')}
                        // nav-link
                    >
                        {isMobile ? (
                            <>
                                <Icon name="more-vertical" />
                                <span>More</span>
                                <span className="rui-dropdown-circle" />
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="more-vertical" />
                            </span>
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu tag="ul" className="nav dropdown-menu" modifiers={{ offset: { offset: '0,12' } }}>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleNightMode"
                                    checked={settings.night_mode}
                                    onChange={() => {
                                        updateInfo({
                                            night_mode: !settings.night_mode,
                                        });
                                    }}
                                />
                                {/* eslint-disable-next-line */}
                                <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                                    <Icon name="moon" />
                                    <span>Night Mode</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleSpotlightMode"
                                    checked={settings.spotlight_mode}
                                    onChange={() => {
                                        updateInfo({
                                            spotlight_mode: !settings.spotlight_mode,
                                        });
                                    }}
                                />
                                {/* eslint-disable-next-line */}
                                <label className="dropdown-item custom-control-label" htmlFor="toggleSpotlightMode">
                                    <Icon name="square" />
                                    <span>Spotlight Mode</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleSectionLines"
                                    checked={settings.show_section_lines}
                                    onChange={() => {
                                        updateInfo({
                                            show_section_lines: !settings.show_section_lines,
                                        });
                                    }}
                                />
                                {/* eslint-disable-next-line */}
                                <label className="dropdown-item custom-control-label" htmlFor="toggleSectionLines">
                                    <Icon name="layout" />
                                    <span>Show section lines</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li className="dropdown-menu-label">Sidebar</li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleDarkSidebar"
                                    checked={settings.sidebar_dark}
                                    onChange={() => {
                                        updateInfo({
                                            sidebar_dark: !settings.sidebar_dark,
                                        });
                                    }}
                                />
                                {/* eslint-disable-next-line */}
                                <label className="dropdown-item custom-control-label" htmlFor="toggleDarkSidebar">
                                    <Icon name="sidebar" />
                                    <span>Dark</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleStaticSidebar"
                                    checked={settings.sidebar_static}
                                    onChange={() => {
                                        updateInfo({
                                            sidebar_static: !settings.sidebar_static,
                                        });
                                    }}
                                />
                                {/* eslint-disable-next-line */}
                                <label className="dropdown-item custom-control-label" htmlFor="toggleStaticSidebar">
                                    <Icon name="sidebar" />
                                    <span>Static</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                        <li className="dropdown-menu-label">Navbar</li>
                        <li>
                            <div className="custom-control custom-switch dropdown-item-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="toggleDarkNavbar"
                                    checked={settings.nav_dark}
                                    onChange={() => {
                                        updateInfo({
                                            nav_dark: !settings.nav_dark,
                                        });
                                    }}
                                />
                                {/* eslint-disable-next-line */}
                                <label className="dropdown-item custom-control-label" htmlFor="toggleDarkNavbar">
                                    <Icon name="menu" />
                                    <span>Dark</span>
                                    <span className="rui-dropdown-circle" />
                                </label>
                            </div>
                        </li>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    render() {
        const { settings } = this.props;

        const { mobileMenuShow, updatePassword } = this.state;
        return (
            <>
                {this.state.modalOpen && <ModalWorkspace onClose={this.handleClose} onSubmit={this.handleSubmitWorkspace} settingsMode={this.state.settingsMode}></ModalWorkspace>}
                {/* Nav Menu */}
                <nav
                    className={classnames(
                        'rui-navbar rui-navbar-top',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_sticky ? 'rui-navbar-sticky' : '',
                        settings.nav_fixed ? 'rui-navbar-fixed' : '',
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
                    )}
                >
                    {updatePassword && (
                        <ChangePassword
                            onClose={() => {
                                this.setState({ updatePassword: false });
                            }}
                            onSubmit={() => {
                                this.setState({ updatePassword: false });
                            }}
                        />
                    )}
                    <div className="rui-navbar-brand">
                        <button className="yay-toggle rui-yaybar-toggle mr-3" type="button">
                            <span />
                        </button>
                        {settings.nav_logo_path ? (
                            <Link to={settings.nav_logo_url + this.props.location.search} className="rui-navbar-logo">
                                <img
                                    src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path}
                                    alt=""
                                    width={settings.nav_logo_width}
                                />
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={`container${settings.nav_container_fluid ? '-fluid' : ''}`}>
                        <div className="rui-navbar-content">
                            <ul className="nav">{this.renderSubmenus(settings.navigation)}</ul>
                            <ul className="nav rui-navbar-right">{this.renderRightMenuItems()}</ul>
                        </div>
                    </div>
                </nav>

                {/* Mobile Menu */}
                <nav
                    className={classnames(
                        'rui-navbar rui-navbar-mobile',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
                        mobileMenuShow ? 'rui-navbar-show' : '',
                    )}
                >
                    <div className="rui-navbar-head">
                        {!mobileMenuShow ? (
                            <button className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle" type="button" aria-label="Toggle side navigation">
                                <span />
                            </button>
                        ) : (
                            ''
                        )}
                        {settings.nav_logo_path ? (
                            <Link to={settings.nav_logo_url} className="rui-navbar-logo mr-auto">
                                <img
                                    src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path}
                                    alt=""
                                    width={settings.nav_logo_width}
                                />
                            </Link>
                        ) : (
                            ''
                        )}
                        <Dropdown tag="div" direction="up" showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar">
                                <img src={this.state.avatar} alt="" />
                                {this.state.login}
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu dropdown-menu-agora">
                                <li className="nav-profile">
                                    <img src={this.state.avatar} alt="" />
                                    <div className="nav-item">
                                        <h3 className="nav-profile-title">{this.props.auth.login}</h3>
                                    </div>
                                    <small className="text-grey-6 mt-2 mb-15">{this.state.role}</small>
                                </li>
                                {this.props.auth.idp !== true && (
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" onClick={this.changePassword}>
                                            <Icon name="shield" />
                                            <span>Change password</span>
                                            <span className="rui-nav-circle" />
                                        </Link>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link to="#" className="nav-link" onClick={this.logOut}>
                                        <Icon name="log-out" />
                                        <span>Log Out</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button
                            className="navbar-toggler rui-navbar-toggle ml-5"
                            onClick={() => {
                                this.setState({
                                    mobileMenuShow: !mobileMenuShow,
                                });
                            }}
                        >
                            <span />
                        </button>
                    </div>
                    <Collapse isOpen={mobileMenuShow} className="navbar-collapse rui-navbar-collapse">
                        <div className="rui-navbar-content">
                            <ul className="nav">
                                {this.renderSubmenus(settings.navigation, true)}
                                {this.renderRightMenuItems(true)}
                            </ul>
                        </div>
                    </Collapse>
                </nav>
                <div
                    className="rui-navbar-bg"
                    onClick={() => {
                        this.setState({
                            mobileMenuShow: !mobileMenuShow,
                        });
                    }}
                    onKeyUp={() => {}}
                    role="button"
                    tabIndex={0}
                />
            </>
        );
    }
}

PageNavbar.propTypes = {
    location: PropTypes.any.isRequired,

    workspaces: PropTypes.array.isRequired,
    settings: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,

    flush_cache: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    post_workspace: PropTypes.func.isRequired,
    updateInfo: PropTypes.func.isRequired,
    delete_workspace: PropTypes.func.isRequired,
    get_workspaces: PropTypes.func.isRequired,
};

export default connect(
    ({ workspaces, settings, app, auth }) => ({
        workspaces,
        settings,
        app,
        auth,
    }),
    {
        flush_cache,
        signOut,
        post_workspace,
        updateInfo,
        delete_workspace,
        get_workspaces,
    },
)(PageNavbar);
