/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import update from 'immutability-helper';

/**
 * Internal Dependencies
 */
import { makeCancelable } from '../../../store/utils';
import { get_scripts, get_groups, get_events } from '../../../actions';
import { get_class_list } from '../../../actions/gael';
import { update_workspace_settings } from '../../../actions/users';

import { observe, UI_NOCODE } from './TaskEvent';
import { ScriptTypes } from '../components/ItemTypes';
import { SCRIPT_ACTIONS } from '../../../types/event';
/**
 * Component
 */

const INITIAL_COUNTER = { dev: 0, prod: 0, [ScriptTypes.SCRIPT_STD]: 0, [ScriptTypes.SCRIPT_DIALOG]: 0, [ScriptTypes.SCRIPT_ALARM]: 0, [ScriptTypes.SCRIPT_ADVANCED]: 0 };
export const TasksContext = React.createContext(); //exporting context object
class ListProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tasks: [],
            groups: [],
            events: [],
            gael_classes:[],
            tags: [],
            open_scripts: [],
            active_tab: 'scripts',
            counter: { ...INITIAL_COUNTER },
        };
        this.flush = observe(this.eventsLoop);
    }
    componentDidMount() {
        this.updateEnvironment();
    }
    componentWillUnmount() {
        this.classListCancelable?.cancel();
        this.loadScripts?.cancel();
        this.loadEvents?.cancel();
        this.getGroups?.cancel();
        this.flush();
    }
    componentDidUpdate(prevProps, prevstate) {
        if (prevProps.app.query.ws != this.props.app.query.ws) {
            this.updateEnvironment();
        } else {
            if (prevstate.active_tab == "events" && this.state.active_tab != prevstate.active_tab) {
                this.getEvents();
            }
        }
    }

    updateEnvironment() {
        Promise.all([this.getScripts(), this.getEvents(), this.build_group(), this.getClasses()])
            .then(() => {
                const groups = [...this.state.groups];
                groups.forEach((g) => {
                    g.events = [];
                    g.list.forEach((device) => {
                        const e = this.state.events.find((e) => {
                            return e.deviceName == device;
                        });
                        e && g.events.push(e);
                    });
                });
                this.setState({ groups, active_tab: this.props.app.settings.active_tab }, () => {
                    window.jQuery(window).trigger('rui-ajax-update-tab');
                });
            })
            .catch((error) => {
                if (error.isCanceled === true) return;
            });
    }

    eventsLoop = (action, context) => {
        switch (action) {
            // case UI_NOCODE.UPDATE_PROD:
            //     {
            //         const scriptIndex = this.state.tasks.findIndex((s) => s.script_id == context.script_id);
            //         const currentScript = this.state.tasks[scriptIndex];
            //         currentScript.production = context.production;
            //         currentScript.updateAt = Date.now();
            //         const tasks = update(this.state.tasks, {
            //             $splice: [
            //                 [scriptIndex, 1],
            //                 [0, 0, currentScript],
            //             ],
            //         });
            //         this.setState({ script: context, tasks });
            //     }
            //     break;
            case UI_NOCODE.REFRESH:
                this.getScripts();
                break;
            default:
        }
    };

    getEvents = () => {
        this.loadEvents = makeCancelable(this.props.get_events(this.props.app.query.ws));
        this.loadEvents.promise
            .then((result) => {
                this.loadEvents = null;
                this.setState({ loading: false, events: result.data });
            })
            .catch((error) => {
                this.loadEvents = null;
                if (error.isCanceled === true) return;
                this.setState({ events: [] });
            });
        return this.loadEvents.promise;
    };

    getClasses = () => {
        this.classListCancelable = makeCancelable(this.props.get_class_list(this.props.app.query.ws, { with_intents: true }));
        this.classListCancelable.promise
            .then((result) => {
                this.classListCancelable = null;
                this.setState({ gael_classes: result });
            })
            .catch((error) => {
                this.classListCancelable = null;
                if (error.isCanceled === true) return;
                this.setState({ gael_classes: [] });
            });
        return this.classListCancelable.promise;
    };
    getScripts = () => {
        this.setState({ loading: true });
        this.loadScripts?.cancel();
        this.loadScripts = makeCancelable(this.props.get_scripts(this.props.app.query.ws));
        this.loadScripts.promise
            .then((result) => {
                this.loadScripts = null;
                const tags = {};
                const open_scripts = [];
                const counter = { ...INITIAL_COUNTER };
                const tasks = result.data
                    .map((s) => {
                        try {
                            const index = this.props.app.settings.open_scripts.findIndex((script_id) => s.script_id == script_id);
                            if (index >= 0) {
                                open_scripts[index] = { ...s, selected: new Date(s.updateAt).getTime() };
                            }
                        } catch {
                            /* */
                        }

                        s.production ? counter.prod++ : counter.dev++;
                        const t = s.type ? s.type : ScriptTypes.SCRIPT_STD;
                        counter[t] = (counter[t] || 0) + 1;

                        s.tags && s.tags.forEach((t) => (tags[t] = (tags[t] || 0) + 1));
                        return { ...s, value: s.script_id, label: s.name };
                    })
                    .sort((item1, item2) => {
                        const d1 = new Date(item1.updateAt);
                        const d2 = new Date(item2.updateAt);
                        if (d1.getTime() > d2.getTime()) return -1;
                        if (d1.getTime() < d2.getTime()) return 1;
                        return 0;
                    });
                this.setState({
                    loading: false,
                    tasks,
                    open_scripts: open_scripts.filter((t) => !!t),
                    counter,
                    tags,
                });
            })
            .catch((error) => {
                this.loadScripts = null;
                if (error.isCanceled === true) return error;
                this.setState({ loading: false, tasks: [] });
            });
        return this.loadScripts.promise;
    };

    build_group = () => {
        this.getGroups = makeCancelable(this.props.get_groups(this.props.app.query.ws));
        this.getGroups.promise
            .then((result) => {
                if (result && result.groups) {
                    this.setState({ groups: result.groups.sort((a, b) => a.group_name.localeCompare(b.group_name)) });
                }
            })
            .catch((error) => {
                if (error.isCanceled === true) return error;
            });
        return this.getGroups.promise;
    };

    toggleTab(name) {
        if (this.state.active_tab == name) return;
        //name === 'events' ? this.props.history.push('/workflows/events') : this.props.history.push('/workflows/scripts');
        this.setState({
            active_tab: name,
        });
        if (name.length < 25) {
            this.props.update_workspace_settings(this.props.app.query.ws, { active_tab: name });
        }
    }
    onSelectScript = (script) => {
        if (typeof script == 'string') {
            if (script == 'scripts') {
                this.getScripts();
            }
            this.toggleTab(script);
            return;
        }
        this.toggleTab(script.script_id);
        let { open_scripts } = this.state;
        const idx = open_scripts.findIndex((s) => s.script_id == script.script_id);
        if (idx == -1) open_scripts = [{ ...script, selected: Date.now() }, ...open_scripts];
        else {
            open_scripts = [...open_scripts.slice(0, idx), { ...script, selected: Date.now() }, ...open_scripts.slice(idx + 1)];
        }
        if (open_scripts.length > 5) {
            let old = null;
            let delidx = -1;
            open_scripts.forEach((s, i) => {
                if (old > 0) {
                    old = Math.min(s.selected, old);
                    if (old == s.selected) delidx = i;
                } else {
                    old = s.selected;
                    delidx = i;
                }
            });
            if (delidx >= 0) {
                open_scripts = [...open_scripts.slice(0, delidx), ...open_scripts.slice(delidx + 1)];
            }
        }
        this.props.update_workspace_settings(this.props.app.query.ws, {
            active_tab: script ? script.script_id : '',
            open_scripts: open_scripts.map((s) => s.script_id),
        });
        this.setState({ active_tab: script.script_id, open_scripts }, () => {
            window.jQuery(window).trigger('rui-ajax-update-tab');
        });
    };

    onCloseScript = (script_id, force) => {
        if (force || this.state.active_tab == script_id) {
            this.onSelectScript('scripts');
        }
        this.setState(
            {
                open_scripts: this.state.open_scripts.filter((s) => s.script_id != script_id),
            },
            () => {
                window.jQuery(window).trigger('rui-ajax-update-tab');
                this.props.update_workspace_settings(this.props.app.query.ws, {
                    active_tab: this.state.active_tab,
                    open_scripts: this.state.open_scripts.map((s) => s.script_id),
                });
            },
        );
    };

    onRenameScript = (script) => {
        let { open_scripts } = this.state;
        const idx = open_scripts.findIndex((s) => s.script_id == script.script_id);
        if (idx == -1) return;
        if (open_scripts[idx].name == script.name) return;
        open_scripts = [...open_scripts.slice(0, idx), { ...script, selected: Date.now() }, ...open_scripts.slice(idx + 1)];
        this.setState({ open_scripts });
    };

    onEvent = (action, payload) => {
        switch (action) {
            case SCRIPT_ACTIONS.OPEN:
                this.onSelectScript(payload);
                return;
            case SCRIPT_ACTIONS.RENAME:
                this.onRenameScript(payload);
                return;
            case SCRIPT_ACTIONS.DELETE:
                this.onCloseScript(payload, true);
                return;
            case SCRIPT_ACTIONS.CLOSE:
                this.onCloseScript(payload, false);
                return;
        }
    };

    render() {
        return (
            <TasksContext.Provider
                value={{
                    state: this.state,
                    onEvent: this.onEvent,
                }}
            >
                {this.props.children}
            </TasksContext.Provider>
        );
    }
}

ListProvider.propTypes = {
    app: PropTypes.any.isRequired,
    children: PropTypes.any,
    get_events: PropTypes.func.isRequired,
    get_scripts: PropTypes.func.isRequired,
    get_groups: PropTypes.func.isRequired,
    update_workspace_settings: PropTypes.func.isRequired,
    get_class_list: PropTypes.func.isRequired,
};
export default connect(({ app }) => ({ app }), {
    get_scripts,
    get_events,
    get_groups,
    update_workspace_settings,
    get_class_list,
})(ListProvider);
