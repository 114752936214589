/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CGU from './pages/CGU';

/**
 * Internal Dependencies
 */
import RoutesList from './pages';
import NotFoundPage from './pages/404';
import { Helmet } from 'react-helmet';

const TITLE = 'Agora Console';
/**
 * Component
 */
class Routes extends Component {
    constructor(props) {
        super(props);
        this.pathname = '';
        this.search = '';
    }

    render() {
        const { location, auth } = this.props;
        const ValidateCGU = auth?.token && auth?.cgu ? CGU : null;

        return (
            <Switch location={location}>
                {Object.keys(RoutesList)
                    .filter((path) => {
                        const tmp = RoutesList[path];
                        if (tmp?.always) return true; // sign-in, authenticate, ...
                        if (auth.limited) {
                            return !!tmp?.webview;
                        }
                        const info = this.props.settings.navigation_sidebar[path];
                        if (info?.scopes && this.props.auth.scopes?.hasOwnProperty(info.scopes) == true) return true;
                        if (info?.limited) return info.limited.find((elem) => elem == auth.role) != null;
                        return true;
                    })
                    .map((path) => {
                        const tmp = RoutesList[path];
                        const RouteInner = ValidateCGU && !tmp.skipcgu ? ValidateCGU : tmp.component ? tmp.component : tmp;
                        return (
                            <Route
                                key={path}
                                path={tmp.alias ?? path}
                                exact
                                render={() => {
                                    if (tmp?.webview) {
                                        this.pathname = this.props.location.pathname;
                                        this.search = this.props.location.search;
                                    }
                                    return (
                                        <>
                                            <Helmet>
                                                <title>{tmp.title || TITLE}</title>
                                            </Helmet>
                                            <RouteInner />
                                        </>
                                    );
                                }}
                            />
                        );
                    })}

                {/* 404 */}
                <Route
                    render={() => {
                        if (auth.limited && this.pathname) {
                            return <Redirect to={this.pathname + this.search} />;
                        }
                        return <NotFoundPage />;
                    }}
                />
            </Switch>
        );
    }
}

Routes.propTypes = {
    location: PropTypes.any.isRequired,
    settings: PropTypes.any.isRequired,
    auth: PropTypes.any.isRequired,
};

export default connect(({ settings, auth }) => ({
    settings,
    auth,
}))(Routes);
