import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const GroupBox = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <path d="M 4,6 6,6 9,2 9,6 20,6 c 1,0 2,1 2,2 v 10 c 0,1 0,2 -2,2 L 9,20 9,16 6,20 H 4 C 3,20 2,19.1 2,18 V 8 C 2,7 3,6 4,6 Z"></path>
        </svg>
    );
});

GroupBox.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GroupBox.displayName = 'GroupeBox';

export default GroupBox;
