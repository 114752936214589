/**
 * External Dependencies
 */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
/**
 * Internal Dependencies
 */
import Icon from '../../components/icon';
import { convertDate, normalize } from '../../tools';
import withLocalStorage from '../../components/with-local-storage';
import * as Roles from '../../types/roles';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Badge } from 'reactstrap';
const MySwal = withReactContent(Swal);

const UsersTable = ({ users, company, filter, companies, onDelete, onEdit, rowPerPage }) => {
    const [isAll] = useState(company.value === '');
    const columns = useMemo(
        () => [
            {
                name: 'Name',
                selector: (user) => user.login,
                sortable: true,
            },
            {
                name: 'Email',
                selector: (user) => user.email,
                sortable: true,
            },
            {
                name: 'Organization',
                selector: (user) => companies[user.company_id] || '...',
                omit: !isAll,
                sortable: true,
            },
            {
                name: 'Provider',
                selector: (user) => (user.idp ? <Badge color="primary">External</Badge> : <Badge color="brand">Agora</Badge>),
                sortable: true,
            },
            {
                name: 'Role',
                selector: (user) => {
                    const role = Roles._roles[user.role] || Roles._default_role;
                    return role.label;
                },
                sortable: true,
            },
            {
                name: 'Created at',
                selector: (user) => convertDate(user.created_at),
                sortable: true,
            },
            {
                name: '',
                width: '100px',
                right: true,
                selector: (user) => (
                    <>
                        <button
                            type="button"
                            className="btn btn-custom-round btn-custom-round-tab mr-10"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEdit(user);
                            }}
                        >
                            <Icon name="edit" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-custom-round btn-custom-round-tab"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                MySwal.fire({
                                    position: 'top',
                                    title: `Are you sure to delete "${user.login}"`,
                                    icon: 'warning',
                                    html: "You won't be able to revert this!",
                                    showCancelButton: true,
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        onDelete(user);
                                    }
                                });
                            }}
                        >
                            <Icon name="x" />
                        </button>
                    </>
                ),
            },
        ],
        [onDelete, onEdit, companies, isAll],
    );

    const datas = useMemo(() => {
        const filter_string = normalize(filter);
        return filter_string.length
            ? users.filter((user) => {
                  const email = normalize(user.email);
                  const login = normalize(user.login);
                  return login.indexOf(filter_string) != -1 || email.indexOf(filter_string) != -1;
              })
            : users;
    }, [users, filter]);

    return (
        <DataTable
            persistTableHead
            noDataComponent="No data available"
            columns={columns}
            pagination
            data={datas}
            paginationPerPage={rowPerPage.get()}
            onChangeRowsPerPage={(rpp) => {
                rowPerPage.set(rpp);
            }}
        />
    );
};

UsersTable.defaultProps = {
    filter: '',
};
UsersTable.propTypes = {
    filter: PropTypes.string.isRequired,

    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    company: PropTypes.object.isRequired,
    companies: PropTypes.object.isRequired,

    rowPerPage: PropTypes.any,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

export default withLocalStorage(UsersTable);
